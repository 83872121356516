// StudentSettings.jsx
import React from "react";
import { NavLink } from "react-router-dom";

const StudentSettings = () => {
  return (
    <>
      <h3 className="my-4">Student account settings</h3>

      <div className="d-flex gap-3 mb-4">
        <div className="mt-2">
          <i className="fa-solid fa-eye" style={{ color: "#adcd4b" }}></i>
        </div>
        <div className="Profile_content d-flex flex flex-column gap-2">
          <h6 className="m-0">Post visibility</h6>
          <p>Select how your posts are made public</p>
          {/* <NavLink to="/studentAcountOpening">Open student account</NavLink> */}
          <select class="form-select form-select-lg mb-3 fs-6" aria-label=".form-select-lg example">
            <option selected>Always make public</option>
            <option value="1">Decide while posting</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default StudentSettings;
