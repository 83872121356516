import React from "react";
import { NavLink } from "react-router-dom";

const Message = () => {
  return (
    <>
      <div className="container-fluid container-xxl mt-3 user_message_section">
        <div className="row message_page_header p-2 align-items-center">
          <div className="col-lg-3 col-md-3 col-sm-5 col-12 ">
            <div className="d-flex align-items-center gap-3 justify-content-evenly chat_header flex-lg-nowrap flex-xl-nowrap flex-wrap ">
              <div className="icon_shadow">
                <i class="fa-solid fa-gear fa-lg "></i>
              </div>
              <div>
                <div className="p-2  text-center icon_shadow">
                  <i class="fa-solid fa-plus fa-lg"></i>
                </div>
              </div>
              <div className=" p-0 border">
                <span class="badge text-bg-primar p-3 rounded-0">
                  {" "}
                  <i class="fa-solid fa-message fa-lg  text-white"></i>
                </span>
                <span class="badge  p-3 rounded-0">
                  {" "}
                  <i class="fa-solid fa-address-book fa-lg text-dark"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-7 col-12 center_header_content my-lg-0 my-xl-3 my-3">
            <h5 className="mb-0 text-center">Content writer for blog post and newsletters</h5>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 header_search">
            <div>
              <div className="d-flex align-items-center gap-3 icon_shadow">
                <div className="position-relative w-100 Search_div">
                  <i class="fa-solid fa-magnifying-glass"></i>
                  <input type="search" placeholder="Search this conversation" class="form-control ps-5" />
                </div>
                <i class="fa-solid fa-phone fa-lg"></i>
                <i class="fa-solid fa-ellipsis fa-lg"></i>
              </div>
            </div>
          </div>
        </div>

        {/*================== second row start=================== */}

        <div className="row message_body">
          <div className="col-lg-4 col-md-5 pt-3">
            <div className="mt-3">
              <div className=" position-relative Search_div">
                <i class="fa-solid fa-magnifying-glass"></i>
                <input type="search" placeholder="Search this conversation" class="form-control mb-3 ps-5" />
              </div>
              <div className="frind_chat_box">
                {/* 0ne */}

                <div class="d-flex align-items-center frind_msg gap-2 border-bottom p-2 mb-2">
                  <img src="teacher-mk/user_3177440.png" alt="" width="40px" />
                  <div className="friend_name">
                    <div className="d-flex">
                      <h5 className="m-0">gurpreet singh </h5>
                      <span>5:00 PM</span>
                    </div>

                    <h6 className="mt-1">Fernando Viernes up Fernando Viernes up </h6>
                    <p>Lorem ipsum dolor sit amet commodi dolorem praesentium ipsam esse repellendus amet laborum perspiciatis error quo!</p>
                  </div>
                </div>

                {/*two */}

                <div class="d-flex align-items-center frind_msg gap-2 border-bottom p-2 mb-2">
                  <img src="teacher-mk/user_3177440.png" alt="" width="40px" />
                  <div className="friend_name">
                    <div className="d-flex">
                      <h5 className="m-0">gurpreet singh </h5>
                      <span>5:00 PM</span>
                    </div>

                    <h6 className="mt-1">Fernando Viernes up Fernando Viernes up </h6>
                    <p>Lorem ipsum dolor sit amet commodi dolorem praesentium ipsam esse repellendus amet laborum perspiciatis error quo!</p>
                  </div>
                </div>
                {/* three */}

                <div class="d-flex align-items-center frind_msg gap-2 border-bottom p-2 mb-2">
                  <img src="teacher-mk/user_3177440.png" alt="" width="40px" />
                  <div className="friend_name">
                    <div className="d-flex">
                      <h5 className="m-0">gurpreet singh </h5>
                      <span>5:00 PM</span>
                    </div>

                    <h6 className="mt-1">Fernando Viernes up Fernando Viernes up </h6>
                    <p>Lorem ipsum dolor sit amet commodi dolorem praesentium ipsam esse repellendus amet laborum perspiciatis error quo!</p>
                  </div>
                </div>
                {/* three */}

                <div class="d-flex align-items-center frind_msg gap-2 border-bottom p-2 mb-2">
                  <img src="teacher-mk/user_3177440.png" alt="" width="40px" />
                  <div className="friend_name">
                    <div className="d-flex">
                      <h5 className="m-0">gurpreet singh </h5>
                      <span>5:00 PM</span>
                    </div>

                    <h6 className="mt-1">Fernando Viernes up Fernando Viernes up </h6>
                    <p>Lorem ipsum dolor sit amet commodi dolorem praesentium ipsam esse repellendus amet laborum perspiciatis error quo!</p>
                  </div>
                </div>
                {/* three */}
                <div class="d-flex align-items-center frind_msg gap-2 border-bottom p-2 mb-2">
                  <img src="teacher-mk/user_3177440.png" alt="" width="40px" />
                  <div className="friend_name">
                    <div className="d-flex">
                      <h5 className="m-0">gurpreet singh </h5>
                      <span>5:00 PM</span>
                    </div>

                    <h6 className="mt-1">Fernando Viernes up Fernando Viernes up </h6>
                    <p>Lorem ipsum dolor sit amet commodi dolorem praesentium ipsam esse repellendus amet laborum perspiciatis error quo!</p>
                  </div>
                </div>
                {/* three */}
                <div class="d-flex align-items-center frind_msg gap-2 border-bottom p-2 mb-2">
                  <img src="teacher-mk/user_3177440.png" alt="" width="40px" />
                  <div className="friend_name">
                    <div className="d-flex">
                      <h5 className="m-0">gurpreet singh </h5>
                      <span>5:00 PM</span>
                    </div>

                    <h6 className="mt-1">Fernando Viernes up Fernando Viernes up </h6>
                    <p>Lorem ipsum dolor sit amet commodi dolorem praesentium ipsam esse repellendus amet laborum perspiciatis error quo!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-7">
            <div className="chat_box position-relative">
              <div class="text-center pt-5 mb-3">Today</div>
              <div className="friend_chat">
                {/* chat start=========================== */}

                <div className="my_chat_msg w-100">
                  <div>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti repellat inventore suscipit.</p>
                  </div>
                </div>

                <div className="Friend_chat_msg w-100">
                  <div>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti repellat inventore suscipit.</p>
                  </div>{" "}
                </div>
                <div className="my_chat_msg w-100">
                  <div>
                    <p>Lorem .</p>
                  </div>
                </div>
                <div className="Friend_chat_msg w-100">
                  <div>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti repellat inventore suscipit.</p>
                  </div>{" "}
                </div>
                <div className="Friend_chat_msg w-100">
                  <div>
                    <p>Lorem .</p>
                  </div>{" "}
                </div>

                <div className="my_chat_msg w-100">
                  <div>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti repellat inventore suscipit.</p>
                  </div>
                </div>
                <div className="Friend_chat_msg w-100">
                  <div>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti repellat inventore suscipit.</p>
                  </div>{" "}
                </div>

                <div className="my_chat_msg w-100">
                  <div>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti repellat inventore suscipit.</p>
                  </div>
                </div>
                <div className="my_chat_msg w-100">
                  <div>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti repellat inventore suscipit.</p>
                  </div>
                </div>
                {/* chat close=================== */}
              </div>
              <div className="open_chat position-absolute bottom-0 w-100">
                <div className="msg_send_box position-relative d-flex align-items-center">
                  <div className="msg_send_icons position-absolute" style={{ left: "20px" }}>
                    <i class="fa-solid fa-gear fa-lg "></i>
                    <i class="fa-regular fa-face-smile fa-lg mx-lg-3 mx-md-3 mx-1"></i>
                    <i class="fa-solid fa-paperclip fa-lg"></i>
                  </div>
                  <textarea class="form-control rounded-pill mx-2 py-3" rows="1" cols="30" style={{ paddingLeft: "130px", resize: "none" }}></textarea>
                  <button type="button" class="btn-primar rounded-circle me-2">
                    <i class="fa-regular fa-paper-plane text-white"></i>
                  </button>

                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Message;
