import React from "react";

const ReferandEarn = () => {
  return (
    <>
      <section className="pt-3 pb-3">
        <div className="container">
          <div className="d-flex justify-content-center my-5">
            <div className="Refer_earn_page">
              <h3>
                <i className="fa-solid fa-coins me-3" style={{ color: "#adcd4b" }}></i>Refer and earn coins
              </h3>
              <ul>
                <li>When you refer someone and they buy coins, you get 10% of the coins they buy.</li>
                <li>
                  When a tutor joins, you get referral coins according to the referred <a href="#search_bar">tutor's country.</a>
                </li>
              </ul>

              <h4>Share your referral link</h4>
              <div className="d-flex align-items-baseline">
                <input className="form-control" id="text" type="text" readonly value="https://www.teach.com/?r=883O" role="link" />

                <button id="copy" tooltip="Copy to clipboard">
                  Copy
                </button>
              </div>
              <h4>Referral Coins for Countries</h4>
              <p>When a tutor joins using your referral link, you get coins based on their location.</p>
              <label htmlFor="search">
                {" "}
                <i className="fa-solid fa-magnifying-glass me-3" style={{ color: " #adcd4b" }}></i>Search Country
              </label>

              <input id="search_bar" className="form-control" type="search" placeholder="Search......" />

              <div className="table_box mt-3">
                <table className="table table-striped table-bordered display" id="example">
                  <thead>
                    <tr>
                      <th style={{ width: "65%" }}>Country</th>
                      <th>Bonus Coins</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Afghanistan</td>
                      <td>7</td>
                    </tr>
                    <tr>
                      <td>Åland Islands</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>Albania</td>
                      <td>46</td>
                    </tr>
                    <tr>
                      <td>Algeria</td>
                      <td>55</td>
                    </tr>
                    <tr>
                      <td>American Samoa</td>
                      <td>48</td>
                    </tr>
                    <tr>
                      <td>Andorra</td>
                      <td>183</td>
                    </tr>
                    <tr>
                      <td>Angola</td>
                      <td>25</td>
                    </tr>
                    <tr>
                      <td>Anguilla</td>
                      <td>108</td>
                    </tr>
                    <tr>
                      <td>Antarctica</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>Antigua and Barbuda</td>
                      <td>96</td>
                    </tr>
                    <tr>
                      <td>Argentina</td>
                      <td>76</td>
                    </tr>
                    <tr>
                      <td>Armenia</td>
                      <td>33</td>
                    </tr>
                    <tr>
                      <td>Australia</td>
                      <td>183</td>
                    </tr>
                    <tr>
                      <td>Austria</td>
                      <td>180</td>
                    </tr>
                    <tr>
                      <td>Azerbaijan</td>
                      <td>64</td>
                    </tr>
                    <tr>
                      <td>Bahrain</td>
                      <td>190</td>
                    </tr>
                    <tr>
                      <td>Bangladesh</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>Barbados</td>
                      <td>64</td>
                    </tr>
                    <tr>
                      <td>Belarus</td>
                      <td>68</td>
                    </tr>
                    <tr>
                      <td>Belgium</td>
                      <td>169</td>
                    </tr>
                    <tr>
                      <td>Belize</td>
                      <td>30</td>
                    </tr>
                    <tr>
                      <td>Benin</td>
                      <td>8</td>
                    </tr>
                    <tr>
                      <td>Bermuda</td>
                      <td>314</td>
                    </tr>
                    <tr>
                      <td>Bhutan</td>
                      <td>32</td>
                    </tr>
                    <tr>
                      <td>Bolivia</td>
                      <td>27</td>
                    </tr>
                    <tr>
                      <td>Bosnia and Herzegovina</td>
                      <td>42</td>
                    </tr>
                    <tr>
                      <td>Botswana</td>
                      <td>66</td>
                    </tr>
                    <tr>
                      <td>Brazil</td>
                      <td>57</td>
                    </tr>
                    <tr>
                      <td>British Virgin Islands</td>
                      <td>155</td>
                    </tr>
                    <tr>
                      <td>Brunei Darussalam</td>
                      <td>281</td>
                    </tr>
                    <tr>
                      <td>Bulgaria</td>
                      <td>79</td>
                    </tr>
                    <tr>
                      <td>Burkina Faso</td>
                      <td>7</td>
                    </tr>
                    <tr>
                      <td>Burundi</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td>Cambodia</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>Cameroon</td>
                      <td>12</td>
                    </tr>
                    <tr>
                      <td>Canada</td>
                      <td>176</td>
                    </tr>
                    <tr>
                      <td>Cape Verde</td>
                      <td>25</td>
                    </tr>
                    <tr>
                      <td>Cayman Islands</td>
                      <td>160</td>
                    </tr>

                    {/* <!-- copy --> */}

                    <tr role="row" className="even">
                      <td>Cayman Islands</td>
                      <td>160</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Central African Republic</td>
                      <td>3</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Chad</td>
                      <td>9</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Channel Islands</td>
                      <td>0</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Chile</td>
                      <td>90</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>China</td>
                      <td>61</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Colombia</td>
                      <td>53</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Comoros</td>
                      <td>6</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Congo, Dem. Rep.</td>
                      <td>3</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Congo, Rep.</td>
                      <td>25</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Costa Rica</td>
                      <td>63</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Cote d'Ivoire</td>
                      <td>14</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Croatia</td>
                      <td>88</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Cuba</td>
                      <td>44</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Curacao</td>
                      <td>55</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Cyprus</td>
                      <td>134</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Czech Republic</td>
                      <td>129</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Denmark</td>
                      <td>181</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Djibouti</td>
                      <td>13</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Dominica</td>
                      <td>44</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Dominican Republic</td>
                      <td>62</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Ecuador</td>
                      <td>41</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Egypt</td>
                      <td>48</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>El Salvador</td>
                      <td>33</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Equatorial Guinea</td>
                      <td>128</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Eritrea</td>
                      <td>5</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Estonia</td>
                      {/* <!-- <td className="hidden">EST</td> --> */}
                      <td>115</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Ethiopia</td>
                      {/* <!-- <td className="hidden">ETH</td> --> */}
                      <td>8</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Faroe Islands</td>
                      {/* <!-- <td className="hidden">FRO</td> --> */}
                      <td>146</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Fiji</td>
                      {/* <!-- <td className="hidden">FJI</td> --> */}
                      <td>36</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Finland</td>
                      {/* <!-- <td className="hidden">FIN</td> --> */}
                      <td>161</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>France</td>
                      {/* <!-- <td className="hidden">FRA</td> --> */}
                      <td>160</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>French Guiana</td>
                      {/* <!-- <td className="hidden"></td> --> */}
                      <td>0</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>French Polynesia</td>
                      {/* <!-- <td className="hidden">PYF</td> --> */}
                      <td>62</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Gabon</td>
                      {/* <!-- <td className="hidden">GAB</td> --> */}
                      <td>71</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Georgia</td>
                      {/* <!-- <td className="hidden">GEO</td> --> */}
                      <td>39</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Germany</td>
                      {/* <!-- <td className="hidden">DEU</td> --> */}
                      <td>184</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Ghana</td>
                      {/* <!-- <td className="hidden">GHA</td> --> */}
                      <td>17</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Gibraltar</td>
                      {/* <!-- <td className="hidden">GIB</td> --> */}
                      <td>226</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Greece</td>
                      {/* <!-- <td className="hidden">GRC</td> --> */}
                      <td>102</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Greenland</td>
                      {/* <!-- <td className="hidden">GRL</td> --> */}
                      <td>138</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Grenada</td>
                      {/* <!-- <td className="hidden">GRD</td> --> */}
                      <td>54</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Guadeloupe</td>
                      {/* <!-- <td className="hidden"></td> --> */}
                      <td>0</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Guam</td>
                      {/* <!-- <td className="hidden">GUM</td> --> */}
                      <td>112</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Guatemala</td>
                      {/* <!-- <td className="hidden">GTM</td> --> */}
                      <td>30</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Guinea</td>
                      {/* <!-- <td className="hidden">GIN</td> --> */}
                      <td>7</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Guinea-Bissau</td>
                      {/* <!-- <td className="hidden">GNB</td> --> */}
                      <td>7</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Guyana</td>
                      {/* <!-- <td className="hidden">GUY</td> --> */}
                      <td>30</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Haiti</td>
                      {/* <!-- <td className="hidden">HTI</td> --> */}
                      <td>7</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Honduras</td>
                      {/* <!-- <td className="hidden">HND</td> --> */}
                      <td>20</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Hong Kong</td>
                      {/* <!-- <td className="hidden">HKG</td> --> */}
                      <td>223</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Hungary</td>
                      {/* <!-- <td className="hidden">HUN</td> --> */}
                      <td>106</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Iceland</td>
                      {/* <!-- <td className="hidden">ISL</td> --> */}
                      <td>191</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>India</td>
                      {/* <!-- <td className="hidden">IND</td> --> */}
                      <td>26</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Indonesia</td>
                      {/* <!-- <td className="hidden">IDN</td> --> */}
                      <td>45</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Iran</td>
                      {/* <!-- <td className="hidden">IRN</td> --> */}
                      <td>73</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Iraq</td>
                      {/* <!-- <td className="hidden">IRQ</td> --> */}
                      <td>62</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Ireland</td>
                      {/* <!-- <td className="hidden">IRL</td> --> */}
                      <td>266</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Isle of Man</td>
                      {/* <!-- <td className="hidden">IMN</td> --> */}
                      <td>310</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Israel</td>
                      {/* <!-- <td className="hidden">ISR</td> --> */}
                      <td>132</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Italy</td>
                      {/* <!-- <td className="hidden">ITA</td> --> */}
                      <td>139</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Jamaica</td>
                      {/* <!-- <td className="hidden">JAM</td> --> */}
                      <td>34</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Japan</td>
                      {/* <!-- <td className="hidden">JPN</td> --> */}
                      <td>156</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Jersey</td>
                      {/* <!-- <td className="hidden">JEY</td> --> */}
                      <td>181</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Jordan</td>
                      {/* <!-- <td className="hidden">JOR</td> --> */}
                      <td>46</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Kazakhstan</td>
                      {/* <!-- <td className="hidden">KAZ</td> --> */}
                      <td>95</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Kenya</td>
                      {/* <!-- <td className="hidden">KEN</td> --> */}
                      <td>0</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Kiribati</td>
                      {/* <!-- <td className="hidden">KIR</td> --> */}
                      <td>7</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Kosovo</td>
                      {/* <!-- <td className="hidden">KSV</td> --> */}
                      <td>38</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Kuwait</td>
                      {/* <!-- <td className="hidden">KWT</td> --> */}
                      <td>255</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Kyrgyz Republic</td>
                      {/* <!-- <td className="hidden">KGZ</td> --> */}
                      <td>14</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Lao PDR</td>
                      {/* <!-- <td className="hidden">LAO</td> --> */}
                      <td>27</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Latvia</td>
                      {/* <!-- <td className="hidden">LVA</td> --> */}
                      <td>100</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Lebanon</td>
                      {/* <!-- <td className="hidden">LBN</td> --> */}
                      <td>71</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Lesotho</td>
                      {/* <!-- <td className="hidden">LSO</td> --> */}
                      <td>14</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Liberia</td>
                      {/* <!-- <td className="hidden">LBR</td> --> */}
                      <td>3</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Libya</td>
                      {/* <!-- <td className="hidden">LBY</td> --> */}
                      <td>36</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Liechtenstein</td>
                      {/* <!-- <td className="hidden">LIE</td> --> */}
                      <td>509</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Lithuania</td>
                      {/* <!-- <td className="hidden">LTU</td> --> */}
                      <td>117</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Luxembourg</td>
                      {/* <!-- <td className="hidden">LUX</td> --> */}
                      <td>399</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Macau</td>
                      {/* <!-- <td className="hidden">MAC</td> --> */}
                      <td>419</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Macedonia</td>
                      {/* <!-- <td className="hidden">MKD</td> --> */}
                      <td>56</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Madagascar</td>
                      {/* <!-- <td className="hidden">MDG</td> --> */}
                      <td>6</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Malawi</td>
                      {/* <!-- <td className="hidden">MWI</td> --> */}
                      <td>4</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Malaysia</td>
                      {/* <!-- <td className="hidden">MYS</td> --> */}
                      <td>106</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Maldives</td>
                      {/* <!-- <td className="hidden">MDV</td> --> */}
                      <td>70</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Mali</td>
                      {/* <!-- <td className="hidden">MLI</td> --> */}
                      <td>8</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Malta</td>
                      {/* <!-- <td className="hidden">MLT</td> --> */}
                      <td>155</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Marshall Islands</td>
                      {/* <!-- <td className="hidden">MHL</td> --> */}
                      <td>12</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Martinique</td>
                      {/* <!-- <td className="hidden"></td> --> */}
                      <td>0</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Mauritania</td>
                      {/* <!-- <td className="hidden">MRT</td> --> */}
                      <td>16</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Mauritius</td>
                      {/* <!-- <td className="hidden">MUS</td> --> */}
                      <td>79</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Mexico</td>
                      {/* <!-- <td className="hidden">MEX</td> --> */}
                      <td>71</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Micronesia</td>
                      {/* <!-- <td className="hidden">FSM</td> --> */}
                      <td>12</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Moldova</td>
                      {/* <!-- <td className="hidden">MDA</td> --> */}
                      <td>21</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Monaco</td>
                      {/* <!-- <td className="hidden">MCO</td> --> */}
                      <td>423</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Mongolia</td>
                      {/* <!-- <td className="hidden">MNG</td> --> */}
                      <td>46</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Montenegro</td>
                      {/* <!-- <td className="hidden">MNE</td> --> */}
                      <td>64</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Morocco</td>
                      {/* <!-- <td className="hidden">MAR</td> --> */}
                      <td>31</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Mozambique</td>
                      {/* <!-- <td className="hidden">MOZ</td> --> */}
                      <td>5</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Myanmar</td>
                      {/* <!-- <td className="hidden">MMR</td> --> */}
                      <td>23</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Namibia</td>
                      {/* <!-- <td className="hidden">NAM</td> --> */}
                      <td>42</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Nauru</td>
                      {/* <!-- <td className="hidden">NRU</td> --> */}
                      <td>45</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Nepal</td>
                      {/* <!-- <td className="hidden">NPL</td> --> */}
                      <td>10</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Netherlands</td>
                      {/* <!-- <td className="hidden">NLD</td> --> */}
                      <td>196</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>New Caledonia</td>
                      {/* <!-- <td className="hidden">NCL</td> --> */}
                      <td>114</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>New Zealand</td>
                      {/* <!-- <td className="hidden">NZL</td> --> */}
                      <td>141</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Nicaragua</td>
                      {/* <!-- <td className="hidden">NIC</td> --> */}
                      <td>21</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Niger</td>
                      {/* <!-- <td className="hidden">NER</td> --> */}
                      <td>4</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Nigeria</td>
                      {/* <!-- <td className="hidden">NGA</td> --> */}
                      <td>0</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>North Korea</td>
                      {/* <!-- <td className="hidden">PRK</td> --> */}
                      <td>6</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Northern Mariana Islands</td>
                      {/* <!-- <td className="hidden">MNP</td> --> */}
                      <td>49</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Norway</td>
                      {/* <!-- <td className="hidden">NOR</td> --> */}
                      <td>258</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Oman</td>
                      {/* <!-- <td className="hidden">OMN</td> --> */}
                      <td>166</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Pakistan</td>
                      {/* <!-- <td className="hidden">PAK</td> --> */}
                      <td>0</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Palau</td>
                      {/* <!-- <td className="hidden">PLW</td> --> */}
                      <td>61</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Palestine</td>
                      {/* <!-- <td className="hidden"></td> --> */}
                      <td>3</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Panama</td>
                      {/* <!-- <td className="hidden">PAN</td> --> */}
                      <td>89</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Papua New Guinea</td>
                      {/* <!-- <td className="hidden">PNG</td> --> */}
                      <td>14</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Paraguay</td>
                      {/* <!-- <td className="hidden">PRY</td> --> */}
                      <td>36</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Peru</td>
                      {/* <!-- <td className="hidden">PER</td> --> */}
                      <td>49</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Philippines</td>
                      {/* <!-- <td className="hidden">PHL</td> --> */}
                      <td>30</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Poland</td>
                      {/* <!-- <td className="hidden">POL</td> --> */}
                      <td>107</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Portugal</td>
                      {/* <!-- <td className="hidden">PRT</td> --> */}
                      <td>111</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Puerto Rico</td>
                      {/* <!-- <td className="hidden">PRI</td> --> */}
                      <td>139</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Qatar</td>
                      {/* <!-- <td className="hidden">QAT</td> --> */}
                      <td>200</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Reunion</td>
                      {/* <!-- <td className="hidden"></td> --> */}
                      <td>0</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Romania</td>
                      {/* <!-- <td className="hidden">ROU</td> --> */}
                      <td>88</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Russian Federation</td>
                      {/* <!-- <td className="hidden">RUS</td> --> */}
                      <td>102</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Rwanda</td>
                      {/* <!-- <td className="hidden">RWA</td> --> */}
                      <td>8</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Saint Barthélemy</td>
                      {/* <!-- <td className="hidden"></td> --> */}
                      <td>0</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Saint Pierre and Miquelon</td>
                      {/* <!-- <td className="hidden"></td> --> */}
                      <td>128</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Samoa</td>
                      {/* <!-- <td className="hidden">WSM</td> --> */}
                      <td>21</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>San Marino</td>
                      {/* <!-- <td className="hidden">SMR</td> --> */}
                      <td>218</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Sao Tome and Principe</td>
                      {/* <!-- <td className="hidden">STP</td> --> */}
                      <td>12</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Saudi Arabia</td>
                      {/* <!-- <td className="hidden">SAU</td> --> */}
                      <td>40</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Senegal</td>
                      {/* <!-- <td className="hidden">SEN</td> --> */}
                      <td>10</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Serbia</td>
                      {/* <!-- <td className="hidden">SRB</td> --> */}
                      <td>56</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Seychelles</td>
                      {/* <!-- <td className="hidden">SYC</td> --> */}
                      <td>106</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Sierra Leone</td>
                      {/* <!-- <td className="hidden">SLE</td> --> */}
                      <td>7</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Singapore</td>
                      {/* <!-- <td className="hidden">SGP</td> --> */}
                      <td>331</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Sint Maarten (Dutch part)</td>
                      {/* <!-- <td className="hidden">SXM</td> --> */}
                      <td>244</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Slovakia</td>
                      {/* <!-- <td className="hidden">SVK</td> --> */}
                      <td>120</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Slovenia</td>
                      {/* <!-- <td className="hidden">SVN</td> --> */}
                      <td>125</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Solomon Islands</td>
                      {/* <!-- <td className="hidden">SLB</td> --> */}
                      <td>8</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Somalia</td>
                      {/* <!-- <td className="hidden">SOM</td> --> */}
                      <td>0</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>South Africa</td>
                      {/* <!-- <td className="hidden">ZAF</td> --> */}
                      <td>49</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>South Korea</td>
                      {/* <!-- <td className="hidden">KOR</td> --> */}
                      <td>144</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>South Sudan</td>
                      {/* <!-- <td className="hidden">SSD</td> --> */}
                      <td>5</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Spain</td>
                      {/* <!-- <td className="hidden">ESP</td> --> */}
                      <td>140</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Sri Lanka</td>
                      {/* <!-- <td className="hidden">LKA</td> --> */}
                      <td>48</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>St. Kitts and Nevis</td>
                      {/* <!-- <td className="hidden">KNA</td> --> */}
                      <td>98</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>St. Lucia</td>
                      {/* <!-- <td className="hidden">LCA</td> --> */}
                      <td>49</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>St. Martin (French part)</td>
                      {/* <!-- <td className="hidden">MAF</td> --> */}
                      <td>71</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>St. Vincent and the Grenadines</td>
                      {/* <!-- <td className="hidden">VCT</td> --> */}
                      <td>42</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Sudan</td>
                      {/* <!-- <td className="hidden">SDN</td> --> */}
                      <td>17</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Suriname</td>
                      {/* <!-- <td className="hidden">SUR</td> --> */}
                      <td>51</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Svalbard and Jan Mayen</td>
                      {/* <!-- <td className="hidden">SJM</td> --> */}
                      <td>489</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Swaziland</td>
                      {/* <!-- <td className="hidden">SWZ</td> --> */}
                      <td>36</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Sweden</td>
                      {/* <!-- <td className="hidden">SWE</td> --> */}
                      <td>188</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Switzerland</td>
                      {/* <!-- <td className="hidden">CHE</td> --> */}
                      <td>225</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Syrian Arab Republic</td>
                      {/* <!-- <td className="hidden">SYR</td> --> */}
                      <td>11</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Taiwan</td>
                      {/* <!-- <td className="hidden">TWN</td> --> */}
                      <td>182</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Tajikistan</td>
                      {/* <!-- <td className="hidden">TJK</td> --> */}
                      <td>11</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Tanzania</td>
                      {/* <!-- <td className="hidden">TZA</td> --> */}
                      <td>12</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Thailand</td>
                      {/* <!-- <td className="hidden">THA</td> --> */}
                      <td>65</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>The Bahamas</td>
                      {/* <!-- <td className="hidden">BHS</td> --> */}
                      <td>92</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>The Gambia</td>
                      {/* <!-- <td className="hidden">GMB</td> --> */}
                      <td>6</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Timor-Leste</td>
                      {/* <!-- <td className="hidden">TLS</td> --> */}
                      <td>18</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Togo</td>
                      {/* <!-- <td className="hidden">TGO</td> --> */}
                      <td>6</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Tokelau</td>
                      {/* <!-- <td className="hidden">TKL</td> --> */}
                      <td>0</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Tonga</td>
                      {/* <!-- <td className="hidden">TON</td> --> */}
                      <td>20</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Trinidad and Tobago</td>
                      {/* <!-- <td className="hidden">TTO</td> --> */}
                      <td>114</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Tunisia</td>
                      {/* <!-- <td className="hidden">TUN</td> --> */}
                      <td>44</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Turkey</td>
                      {/* <!-- <td className="hidden">TUR</td> --> */}
                      <td>97</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Turkmenistan</td>
                      {/* <!-- <td className="hidden">TKM</td> --> */}
                      <td>68</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Turks and Caicos Islands</td>
                      {/* <!-- <td className="hidden">TCA</td> --> */}
                      <td>106</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Tuvalu</td>
                      {/* <!-- <td className="hidden">TUV</td> --> */}
                      <td>14</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Uganda</td>
                      {/* <!-- <td className="hidden">UGA</td> --> */}
                      <td>9</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Ukraine</td>
                      {/* <!-- <td className="hidden">UKR</td> --> */}
                      <td>32</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>United Arab Emirates</td>
                      {/* <!-- <td className="hidden">ARE</td> --> */}
                      <td>50</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>United Kingdom</td>
                      {/* <!-- <td className="hidden">GBR</td> --> */}
                      <td>160</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>United States</td>
                      {/* <!-- <td className="hidden">USA</td> --> */}
                      <td>218</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>United States Minor Outlying Islands</td>
                      {/* <!-- <td className="hidden"></td> --> */}
                      <td>0</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Uruguay</td>
                      {/* <!-- <td className="hidden">URY</td> --> */}
                      <td>82</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Uzbekistan</td>
                      {/* <!-- <td className="hidden">UZB</td> --> */}
                      <td>26</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Vanuatu</td>
                      {/* <!-- <td className="hidden">VUT</td> --> */}
                      <td>10</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Vatican City</td>
                      {/* <!-- <td className="hidden"></td> --> */}
                      <td>0</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Venezuela</td>
                      {/* <!-- <td className="hidden">VEN</td> --> */}
                      <td>45</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Vietnam</td>
                      {/* <!-- <td className="hidden">VNM</td> --> */}
                      <td>25</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Virgin Islands (U.S.)</td>
                      {/* <!-- <td className="hidden">VIR</td> --> */}
                      <td>132</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Wallis and Futuna</td>
                      {/* <!-- <td className="hidden"></td> --> */}
                      <td>14</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Western Sahara</td>
                      {/* <!-- <td className="hidden"></td> --> */}
                      <td>9</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Yemen, Rep.</td>
                      {/* <!-- <td className="hidden">YEM</td> --> */}
                      <td>8</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Zambia</td>
                      {/* <!-- <td className="hidden">ZMB</td> --> */}
                      <td>15</td>
                    </tr>
                    <tr role="row" className="odd">
                      <td>Zimbabwe</td>
                      {/* <!-- <td className="hidden">ZWE</td> --> */}
                      <td>8</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReferandEarn;
