import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../settings/axiosConfig.jsx";
import $ from "jquery";
import { NavLink } from "react-router-dom";

export const Register = () => {
	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		password: "",
		confirm_password: "",
		user_type: ""
	});

	const [errorMessages, setErrorMessages] = useState({});
	const [successMessage, setSuccessMessage] = useState(false);

	const navigate = useNavigate();

	const { first_name, last_name, email, password, confirm_password, user_type } = formData;

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.post(`users`, formData);

			if (response.data.success === true) {
				console.log(response.data.success);
				setSuccessMessage(response.data.message);
				const email = response.data.data.email;
                const accessToken = response.data.data.token;
                const userType = response.data.data.user_type;
                const status = response.data.success;
                const fname = response.data.data.first_name;
                const lname = response.data.data.last_name;
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('userType', userType);
                localStorage.setItem('status', status);
                localStorage.setItem('email', email);
                localStorage.setItem('fname', fname);
                localStorage.setItem('lname', lname);
                axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                localStorage.setItem('accessToken', accessToken);
				setFormData({
					first_name: "",
					last_name: "",
					email: "",
					password: "",
					confirm_password: "",
					user_type: "",
					email_verified_at: "",
				});

				setErrorMessages({});

				toast.success(successMessage, { autoClose: 2000 });
				// setTimeout()
				navigate("/verify");
			}
		} catch (error) {
			console.log(error);
			setErrorMessages(error.response.data.data);
			toast.error(error.response.data.message, { autoClose: 2000 });
		}
	};

	useEffect(() => {
		$(document).ready(function () {
			$("#show").click(function () {
				$(".demone").hide();
				$(".demotwo").show();

				$("#show").addClass("loginactive");
				$("#hide").removeClass("loginactive");
			});
			$("#hide").click(function () {
				$(".demone").show();
				$(".demotwo").hide();
				$("#hide").addClass("loginactive");
				$("#show").removeClass("loginactive");
			});
		});
	}, []);

	return (
		<>
			<form className="demotwo" style={{ display: "none" }} onSubmit={handleSubmit}>
				<div className="mb-3">
					<label htmlFor="fname">First Name</label>
					<input type="text" name="first_name" value={first_name} onChange={handleChange} id="fname" />
					{errorMessages["first_name"] && (<div className="text-danger pt-2">{errorMessages["first_name"][0]}</div>)}
				</div>

				<div className="mb-3">
					<label htmlFor="lname">Last Name</label>
					<input type="text" name="last_name" value={last_name} onChange={handleChange} id="lname" />
					{errorMessages["last_name"] && (<div className="text-danger pt-2">{errorMessages["last_name"][0]}</div>)}
				</div>

				<div className="mb-3">
					<label htmlFor="email">Email ID</label>
					<input type="email" name="email" value={email} onChange={handleChange} id="email" />
					{errorMessages["email"] && (<div className="text-danger pt-2">{errorMessages["email"][0]}</div>)}
				</div>

				<div className="mb-3">
					<label htmlFor="password">Password</label>
					<input type="password" name="password" value={password} onChange={handleChange} autoComplete="off" id="password" />
					{errorMessages["password"] && (<div className="text-danger pt-2 ">{errorMessages["password"][0]}</div>)}
				</div>

				<div className="mb-3">
					<label htmlFor="confirm_password">Confirm Password</label>
					<input type="password" name="confirm_password" value={confirm_password} onChange={handleChange} id="confirm_password" />
					{errorMessages['confirm_password'] && (
						<div className='text-danger pt-2'>
							{errorMessages['confirm_password'][0]}
						</div>
					)}
				</div>

				<div className="mb-3">
					<label htmlFor="profile">I am a</label>
					<select type="text" name="user_type" value={user_type} onChange={handleChange} id="profile">
						<option>-- Select --</option>
						<option value="student">Student</option>
						<option value="tutor">Tutor</option>
					</select>
					{errorMessages["user_type"] && (<div className="text-danger pt-2">{errorMessages["user_type"][0]}</div>)}
				</div>

				<div className="d-flex align-items-baseline gap-3 my-3">
					<input type="checkbox" id="first_account" />
					<label htmlFor="first_account"> This is my first account.</label>
				</div>
				{/* <NavLink to="/verify"> */}
				<button type="submit">Register</button>
				{/* </NavLink> */}
			</form>
		</>
	);
};
