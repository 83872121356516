import React, { useState, useEffect, useRef, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "../../settings/axiosConfig.jsx";
import $ from 'jquery';
import GeneralSetting from '../../components/General Setting/GeneralSetting.js';

export const Login = () => {
    
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const useForceUpdate = () => {
        const [, forceUpdate] = useState(0);
        return () => forceUpdate((prev) => prev + 1);
    };
    
    const forceUpdate = useForceUpdate(); // Add this line

    const [errorMessages, setErrorMessages] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [userType, setUserType] = useState(null); // Add a state for userType

    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleLogin = async (e) => {
        const userType1 = window.localStorage.getItem('userType');

        e.preventDefault();

        try {
            const response = await axios.post(`login`, formData);

            if (response.data.success === true) {
                console.log(response.data.data);
                setSuccessMessage(response.data.message);
                const email = response.data.data.email;
                const accessToken = response.data.data.token;
                const userType = response.data.data.user_type;
                const status = response.data.success;
                const fname = response.data.data.first_name;
                const lname = response.data.data.last_name;
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('userType', userType);
                localStorage.setItem('status', status);
                localStorage.setItem('email', email);
                localStorage.setItem('fname', fname);
                localStorage.setItem('lname', lname);
                axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                localStorage.setItem('accessToken', accessToken);
                setFormData({
                    email: "",
                    password: ""
                });
                setUserType(userType); // Set userType in the state
                toast.success(successMessage, { autoClose: 2000 });

                navigate('/');
                
                forceUpdate(); // Force a re-render

            }
        } catch (error) {
            if (error instanceof TypeError) {
                console.log(error.message);
            } else {
                console.log(error.response.data.message);
                setErrorMessages(error.response.data.data);
                toast.error(error.response.data.message, { autoClose: 2000 });
            }
        }
    };

    // Render GeneralSetting conditionally based on userType
    const renderGeneralSetting = () => {
        if (userType === "student" || userType === "tutor") {
            return <GeneralSetting userType={userType} />;
        }
        return null;
    };

    return (
        <>

{renderGeneralSetting()}
            <form className="demone" onSubmit={handleLogin}>

                <div className='form-group mb-3'>
                    <label htmlFor="login_email">Email ID</label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} required id='login_email' />
                </div>

                <div className='form-group mb-3'>
                    <label htmlFor="login_password">Password</label>
                    <input type="password" name="password" value={formData.password} onChange={handleChange} autoComplete='off' id='login_password' />
                </div>

                <div className="d-fb align-items-baseline justify-content-between mt-0">
                    <button type="submit">Login</button>
                    <div className="text-center">
                        <NavLink to="/ResetPassward"><p className='forget-passward-text'>Forgot your password?</p></NavLink>
                    </div>
                </div>
            </form>
        </>
    )
}