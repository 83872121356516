import React from "react";
import { NavLink } from "react-router-dom";

const SinglePage = () => {
  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <h4 className="fw-bolder mb-3">English Home tutor required in karachi</h4>

            <div className="accordion mb-5" id="accordionExample">
              <div className="accordion-item">
                <div className="accordion-collaps collapse show" id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <p className="mt-3 mb-4"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                  <div className="accordion-body">
                    <div className="d-flex">
                      {" "}
                      <i className="fa-solid fa-hourglass-start user txt-clr"></i> <h6 className="mt-2">Requires : Part Time</h6>{" "}
                    </div>

                    <div className="d-flex">
                      <i className="fa-solid fa-user user txt-clr"></i> <h6 className="mt-2">Posted By : Shivansh (student)</h6>{" "}
                    </div>

                    <div className="d-flex">
                      {" "}
                      <i className="fa-solid fa-mobile-button user txt-clr"></i> <h6 className="mt-2">Phone verified: +91 9515555555</h6>{" "}
                    </div>

                    <div className="d-flex">
                      {" "}
                      <i className="fa-solid fa-venus-mars user txt-clr"></i> <h6 className="mt-2">Gender preference: None</h6>{" "}
                    </div>

                    <div className="d-flex">
                      {" "}
                      <i className="fa-solid fa-turn-up user txt-clr"></i> <h6 className="mt-2">Level : Expert</h6>{" "}
                    </div>

                    <div className="d-flex">
                      {" "}
                      <i className="fa-solid fa-globe user txt-clr"></i> <h6 className="mt-2"> Available for home tutoring</h6>{" "}
                    </div>
                    <div className="d-flex">
                      {" "}
                      <i className="fa-solid fa-car user txt-clr"></i> <h6 className="mt-2"> Can travel 5km</h6>{" "}
                    </div>
                    <div className="d-flex" style={{ opacity: "0.3" }}>
                      {" "}
                      <i className="fa-solid fa-wifi user txt-clr"></i> <h6 className="mt-2"> Not available online</h6>{" "}
                    </div>
                  </div>
                  <p>
                    <img src="teacher-mk/calender.png" alt="Not loading" className="img-fluid1" />1 mins ago <img src="teacher-mk/location.png" alt="Not loading" className="img-fluid1" /> Jalalpur Jattan, Gujrat, Punjab, Pakistan ~£60month
                  </p>{" "}
                </div>
              </div>
            </div>
            <button type="button" className="btn" style={{ backgroundColor: "rgb(173,205,75)", color: "white" }}>
              contact Shivansh{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SinglePage;
