import React from "react";
import { NavLink } from "react-router-dom";
const AllMessages = () => {
  const cutter = () => {
    let cut = document.getElementById("cut-item");
    cut.style.display = "none";
  };
  const cutter2 = () => {
    let cut = document.getElementById("cut-item2");
    cut.style.display = "none";
  };

  return (
    <>
      <div className="container">
        <div className="Subjects_you_Teach mx-auto">
          <div className="d-fb my-4">
            <h2 className="text-start w-75">All Messages </h2>
            <NavLink to="/UnreadMessages" style={{ textDecoration: "none" }}>
              <button style={{ width: "171px", fontSize: "12px" }} type="button" className="btn after_email_btn m-0 btn bg-clr-green">
                View Unread messages
              </button>
            </NavLink>
          </div>
          <label htmlFor="text">
            {" "}
            <a href="#">Online English teacher needed in Surrey</a>
          </label>
          <input className="form-control mt-2" type="search" />
          <button type="button" className="btn btn-outline-primary mt-4">
            Search
          </button>
          <p>
            No message found. Please <a href="#">contact tutors.</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default AllMessages;
