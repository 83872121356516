import React from 'react'

const ProfilePhoto = () => {
  return (
    <>
      <section className="py-5">
        <div className="container">
          <div className="Profile_Photo_page">
            <h3>Profile Photo</h3>

            <div className="input-group mt-5">
              <input type="file" className="form-control Old_email" id="exampleInputEmail1" aria-describedby="emailHelp" readonly />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ProfilePhoto