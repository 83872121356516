import React from 'react'

const ChangeEmail = () => {
  return (
    <>
      <section className="py-5">
        <div className="container">
          <div className="Change_email_page">
            <h3>Change email</h3>
            <div className="mt-5">
              <label htmlFor="exampleInputEmail1" className="form-label">Old email</label>
              <input type="email" className="form-control Old_email" id="exampleInputEmail1" aria-describedby="emailHelp" readonly value="hogode@mailinator.com" />
            </div>
            <div className="mt-4">
              <label htmlFor="exampleInputEmail1" className="form-label">New email</label>
              <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
            <button>Change Email</button>
          </div>
        </div>
      </section>

    </>
  )
}

export default ChangeEmail