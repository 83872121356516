import React from 'react'
import { NavLink } from 'react-router-dom'

const StudentAcountOpening = () => {
  return (
    <>
      <div className='container'>
        <div className='row '>
          <div className='col-6 border ma p-5 mt-5'>
            <h3 className=''>Open a client/parent/student account?</h3>
            <p>You will be able to contact teachers and post requirements with this account.</p>
            <button type="button" className='btn text-white mt-5' style={{ backgroundColor: "rgb(173, 205, 75)" }}>Cancel</button>
            <button type="button" className='btn  ms-5 mt-5  border text-white' data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ backgroundColor: "rgb(173, 205, 75)" }}>Create account</button>
          </div>
        </div>
      </div>


      {/* <!-- Modal --> */}
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <select className="form-select " aria-label="Default select example">
              <option selected>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StudentAcountOpening;