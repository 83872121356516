import React from "react";
import { NavLink } from "react-router-dom";

const Tutorphoneverify = () => {

  
  return (
    <>
      <div className="container EmailVarified-cls Subjects_you_Teach">
        <div className="row border mt-5  ma EmailVarified-cls">
          <div className="col-12" style={{ padding: "0" }}>
            <div className="w-100 icon-full-div">
              <div className="bg-clr-green1 h-100 icon-div position-relative">
                <div className="skew-div1 position-absolute h-100 position-absolute"></div>
                <i className="fas fa-exclamation-triangle" style={{ color: "white", fontSize: "36px", margin: "5px" }}></i>
              </div>
              <p className="verification-text pt-0 ps-0 ps-lg-3 ps-md-3">Teachers can't see your phone. Please verify your phone number to fix this.</p>
            </div>
          </div>
        </div>
        <div className="mt-5  EmailVarified-cls ">
          <h2>Phone Numbers</h2>
          <br />
          <p className="d-md-inline d-sm-block me-2 mb-3 mb-md-0 mb-lg-0 mb-xl-0">+91-5465432535 </p>
          <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal1">
            Verify
          </button>

          <button type="button" className="btn btn-outline-danger mx-2" data-bs-toggle="modal" data-bs-target="#exampleModal2">
            Delete
          </button>
          <button type="button" className="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#exampleModal4">
            edit
          </button>
          <br></br>
          <br></br>
          <br></br>
          <h3 style={{ color: "black" }}>Add Phone</h3>
          <br />
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Code" />
            <input type="text" className="form-control" placeholder="Number" />
          </div>
          <NavLink to="/RequestDetails">
            {" "}
            <div className="btn border mt-3 ma bg-clr-green after_email_btn px-4">Save</div>
          </NavLink>
        </div>
      </div>

      {/* <DELET></DELET> */}
      <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content delete_profile_confirm_popup">
            <div className="modal-header border-1">
              <h4>Confirm to delete</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">If you delete this phone, all posts associated with this will be closed. No new teachers will be able to contact you on this number. Teachers who have already paid to see your phone may still contact you..</div>
            <div className="modal-footer border-0 justify-content-center">
              <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">
                cancel
              </button>
              <button type="button" className="btn btn-danger" data-bs-dismiss="modal">
                delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <VIEW></VIEW> */}

      <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content delete_profile_confirm_popup">
            <div className="modal-header border-1">
              <h4> Verify Mobile</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">
              +91-5465432535 &nbsp; &nbsp; <a href="">Change </a>
              <br></br>
              <br></br>
              <button type="button" className="btn btn-primary">
                GET OTP
              </button>
              <br></br>
              <br></br>
              <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal13">
                Skip verification{" "}
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* MODEL 3 */}
      <div className="modal fade" id="exampleModal13" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content delete_profile_confirm_popup">
            <div className="modal-header border-1">
              <h4> Are you sure?</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">Teachers may not contact you if you don't verify your phone number.</div>
            <div className="modal-footer border-1 justify-content-center">
              <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">
                Skip verification
              </button>
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                Verify now
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tutorphoneverify;
