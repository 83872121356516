import axios from "axios";

const instance = axios.create({
    baseURL: "https://teach.mrcoders.in/api/",
    headers: {
        apiKey: "KFGhUYU",
    }
});

// Add a request interceptor to set the Authorization header
instance.interceptors.request.use(
  (config) => {
    // Retrieve the token from localStorage or your authentication context
    const accessToken = localStorage.getItem("accessToken");

    // If a token exists, set the Authorization header
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
