import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink } from "react-router-dom";

const Payment = () => {
  const [isDivVisible, setIsDivVisible] = useState(true);

  // Function to toggle the visibility
  const toggleVisibility = () => {
    setIsDivVisible(!isDivVisible);
  };
  // const removeShowClass = () => {};

  return (
    <>
      <div className="cointainer mt-5">
        <div className="row Subjects_you_Teach mx-auto">
          {isDivVisible && (
            <div id="demo" className="row border mt-5 px-0  ma EmailVarified-cls">
              <div className="col-10" style={{ padding: "0" }}>
                <div className="w-100 icon-full-div">
                  <div className="bg-clr-green h-100 icon-div position-relative">
                    <div className="skew-div position-absolute h-100 position-absolute"></div>
                    <i className="fa-solid fa-check " style={{ color: "white", fontSize: "36px", margin: "5px" }}></i>
                  </div>
                  <p className="verification-text" style={{ paddingTop: "0", marginLeft: "20px" }}>
                    No record found
                  </p>
                </div>
              </div>
              <div className="col-2">
                <a href="#" onClick={toggleVisibility}>
                  <i className="fa-solid fa-delete-left fa-2xl" style={{ marginTop: "13px", marginTop: "22px", float: "right" }}></i>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Payment;
