// options.js
import axios from "./axiosConfig.jsx";

export const getOptions = async (optionNames) => {
    try {
        const optionsString = optionNames.join(",");
        const response = await axios.get(`options/get-by-names?options=${optionsString}`);

        if (response.data.success === true) {
            const optionsData = response.data.data;
            const optionsMap = {};

            optionsData.forEach(option => {
                optionsMap[option.option] = option.sub_options;
            });

            return optionsMap;
        }
    } catch (error) {
        throw error;
    }
};
