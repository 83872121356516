import React from "react";

const GoPremium = () => {
  return (
    <>
      <div className="cointainer mt-5 Small_containers">
        <div className="row">
          <div className="col-12">
            <div className="Subjects_you_Teach mx-auto">
              <h3>Premium subscription: Calculate & subscribe</h3>
              <p class=" mb-3 mt-4">What is premium membership?</p>
              <p class=" mb-4 text-danger">Premium subscription not active.</p>
              <div class="alert alert-primary mb-5" role="alert">
                <p>Please enter the coins you wish to use for your monthly premium subscription and check the corresponding rank for each subject.</p>
                <div className="my-3">
                  <label htmlFor="institute">
                    &nbsp;&nbsp;<b>Coins/month:</b>
                  </label>
                  <input className="form-control mt-2" type="number" />
                  &nbsp;
                  <button type="button" class="btn btn-primary mt-3 check_btn">
                    Check Rank
                  </button>
                </div>
              </div>
              <label htmlFor="institute">&nbsp; Your rank at 0 coins/month</label>
              <table class="table text-center table-striped table-bordered mt-3">
                <thead>
                  <tr>
                    <th scope="col">Subject</th>
                    <th scope="col">Brampton</th>
                    <th scope="col">Online</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>English</td>
                    <td>1</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td>English & Literature</td>
                    <td>2</td>
                    <td>224</td>
                  </tr>
                </tbody>
              </table>
              <p className="text-center">Your rank may drop if a competing tutor takes a plan with more coins than you.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoPremium;
