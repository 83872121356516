import React from 'react'
import { NavLink } from 'react-router-dom'

const ResetPassward = () => {
  return (
    <>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-5 ma border ResetPassward-cls' style={{ padding: "30px 20px" }}>
            <h5 className='mb-5'>Reset Passward</h5>
            <div className="mb-3 text-start mt-5">
              <label htmlFor="exampleInputEmail1" className="form-label ">Email address</label>
              <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
              <NavLink to="/Login"><div id="emailHelp" className="form-text text-start mt-4 hover-underline">Click here to go to login screen</div></NavLink>
            </div>
            <NavLink to="/NewPassward"><button type="buttton" className='btn border w-100 mt-4 bg-clr-green'>Submit</button></NavLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassward