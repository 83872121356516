import React from "react";
import { NavLink } from "react-router-dom";
import StudentSettings from "./StudentSettings"; // Import the student settings component
import TeacherSettings from "./TeacherSettings"; // Import the teacher settings component

const GeneralSetting = () => {
  const userType = window.localStorage.getItem('userType');
  return (
    <>
      {/* new page starrt */}

      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="d-grid justify-content-center student_profile_setting">
            <h3 className="mb-4">General Settings {userType}</h3>
              <div className="d-flex gap-3 mb-4">
                <div className="mt-2">
                  <i className="fa-solid fa-user" style={{ color: "#adcd4b" }}></i>
                </div>
                <div className="Profile_content d-flex flex flex-column gap-2">
                  <h6 className="m-0">Profile Live</h6>
                  <p>
                    <i className="fa-solid fa-triangle-exclamation pe-2" style={{ color: "#f8993f" }}></i>If you delete your profile, you will lose all your data permanently.
                  </p>

                  <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Delete profile
                  </a>
                </div>
              </div>

              <div className="d-flex gap-3 mb-4">
                <div className="mt-2">
                  <i className="fa-regular fa-envelope" style={{ color: "#adcd4b" }}></i>
                </div>
                <div className="Profile_content d-flex flex flex-column gap-2">
                  <h6 className="m-0">Email</h6>
                  <p>Your email is user@gmail.com</p>
                  <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal3">
                    Change Email
                  </a>
                </div>
              </div>

              <div className="d-flex gap-3 mb-4">
                <div className="mt-2">
                  <i className="fa-solid fa-phone" style={{ color: "#adcd4b" }}></i>
                </div>
                <div className="Profile_content d-flex flex flex-column gap-2">
                  <h6 className="m-0">Phone</h6>
                  <p>Add, remove, and verify phone numbers</p>
                  <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal4">
                    Manage phone numbers
                  </a>
                </div>
              </div>

              <div className="d-flex gap-3 mb-4">
                <div className="mt-2">
                  <i className="fa-solid fa-signature" style={{ color: "#adcd4b" }}></i>
                </div>
                <div className="Profile_content d-flex flex flex-column gap-2">
                  <h6 className="m-0">Name</h6>
                  <p>Manage how others see your name</p>
                  <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal5">
                    Manage name
                  </a>
                </div>
              </div>

              <div className="d-flex gap-3 mb-4">
                <div className="mt-2">
                  <i className="fa-solid fa-image" style={{ color: "#adcd4b" }}></i>
                </div>
                <div className="Profile_content d-flex flex flex-column gap-2">
                  <h6 className="m-0">Profile photo</h6>
                  <p>Manage your profile picture</p>
                  {/* <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal7"> */}
                  <a href="#" data-bs-toggle="modal" data-bs-target="#exampProfilePhoto">
                    Manage profile photo
                  </a>
                </div>
              </div>
              {userType === "student" && <StudentSettings />}
              {userType === "tutor" && <TeacherSettings />}

              

            
            </div>
          </div>
        </div>
      </section>
      {/* Change name popup  */}

      <div className="modal fade" id="exampleModal6" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">
              <div className="container">
                <div className="change_Numbers_page text-start">
                  <h3>Change name</h3>
                  <label htmlFor="exampleInputEmail1" className="form-label mt-5">
                    Full name
                  </label>
                  <div className="input-group">
                    <input type="email" className="form-control Old_email" id="exampleInputEmail1" aria-describedby="emailHelp" value="Marcia Rhodes " />
                  </div>
                  <label htmlFor="exampleInputEmail1" className="form-label mt-3">
                    ID Proof
                  </label>
                  <div className="input-group">
                    <input type="file" className="form-control Old_email" id="exampleInputEmail1" aria-describedby="emailHelp" />
                  </div>
                  <button>Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1 Please check your email to delete profile. popup  */}

      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content delete_profile_popup">
            <div className="modal-header border-0">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">If you delete your profile, you will lose all your data permanently.</div>
            <div className="modal-footer border-0">
              <button type="button" className="btn btn-secondary px-3 Cancel_btn" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" className="btn btn-primary px-3 Delete_btn" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* 2 2nd popup delete profile. popup  */}
      <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content delete_profile_confirm_popup">
            <div className="modal-header border-0">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">Please check your email to delete profile.</div>
            <div className="modal-footer border-0 justify-content-center">
              <button type="button" className="btn btn-primary px-5 ok_btn" data-bs-dismiss="modal">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!--3 Change email popup start --> */}

      <div className="modal fade" id="exampleModal3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">
              <div className="container">
                <div className="Change_email_page">
                  <h3>Change email</h3>
                  <div className="mt-5 text-start">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Old email
                    </label>
                    <input type="email" className="form-control Old_email" id="exampleInputEmail1" aria-describedby="emailHelp" readonly value="hogode@mailinator.com" />
                  </div>
                  <div className="mt-4 text-start">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      New email
                    </label>
                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                  </div>
                  <button>Change Email</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--4 Phone Numbers modal --> */}

      <div className="modal fade" id="exampleModal4" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">
              <div className="container">
                <div className="Phone_Numbers_page">
                  <h3>Phone Numbers</h3>
                  <div className="mt-5 text-start">
                    <label htmlFor="validationCustomUsername" className="form-label">
                      Add Phone
                    </label>

                    <div className="input-group has-validation">
                      <select className="form-select" aria-label="Default select example">
                        <option value="2" title="93">
                          +93 Afghanistan
                        </option>
                      
                      </select>

                      <input type="tel" className="form-control" id="validationCustomUsername" aria-describedby="inputGroupPrepend" required />
                      <div className="invalid-feedback">Please choose a username.</div>
                    </div>
                  </div>

                  <button>Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--5 Name settings popup  --> */}

      <div className="modal fade" id="exampleModal5" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">
              <div className="container">
                <div className="Name_Numbers_page text-start">
                  <h3>Name settings</h3>
                  <label htmlFor="exampleInputEmail1" className="form-label mt-5">
                    Full name
                  </label>
                  <div className="input-group">
                    <input type="email" className="form-control Old_email" id="exampleInputEmail1" aria-describedby="emailHelp" readonly value="Marcia Rhodes " />
                    <a href="#" className="btn btn-outline-secondary px-4" type="button" id="button-addon2" data-bs-toggle="modal" data-bs-target="#exampleModal6">
                      Edit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--5 Name settings popup  --> */}

      <div className="modal fade" id="exampProfilePhoto" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">
              <div className="container">
                <div className="Profile_Photo_page">
                  <h3>Profile Photo</h3>

                  <div className="input-group my-5">
                    <input type="file" className="form-control Old_email" id="exampleInputEmail1" aria-describedby="emailHelp" readonly />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* page ends  */}
    </>
  );
};

export default GeneralSetting;
