import React from "react";
// import "../../App.css";
import { NavLink } from "react-router-dom";

const Findtutor = () => {
  return (
    <>
      <main className="flex-shrink-0">
        <div className="container-fluid">
          <div className="row content">
            <div className="col-lg-5 div-1 col-md-6">
              <h1 className="Educate-text-cls">FIND BEST!</h1>
              <h1 className="position-relative smart-text">TUTORS FROM ALL COUNTRIES</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis </p>
            </div>
            <div className="col-lg-7 col-md-6 d-flex align-items-center">
              <img src="teacher-mk/19874.jpg" alt="Not loading" className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="container ">
          <div className="row">
            <div className="col-lg-9 col-sm-12 three-boxes-part ma mt-5">
              <div className="row">
                <input type="text" className="col-md-3 border-2px form-box box-1 bg-clr-green white-border-on-focus" style={{ color: "white" }} placeholder="Subject/skill" />

                <input type="text" className="col-md-3 border-2px form-box box-1 bg-clr-green white-border-on-focus" style={{ color: "white" }} placeholder="Location" />
                <button type="button" className="col-md-2 bg-white form-box box-3" style={{ border: "none" }}>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>

        <section className="py-5 Findtutor_page">
          <div className="container ">
            <div className="row gx-5">
              <div className="col-xl-8">
                <NavLink to="/tutor-profile">
                  {" "}
                  <h4 className="fw-bolder mb-3 teacher_name">ramesh verma</h4>
                </NavLink>

                <div className="accordion mb-5" id="accordionExample">
                  <div className="accordion-item">
                    <div className="accordion-header d-flex flex-wrap" id="headingOne">
                      <button className="accordion-button" type="button">
                        English
                      </button>
                      <button className="accordion-button" type="button">
                        Punjabi
                      </button>
                      <button className="accordion-button" type="button">
                        Hindi
                      </button>
                      <button className="accordion-button" type="button">
                        Urdu
                      </button>
                      <button className="accordion-button" type="button">
                        Tamil
                      </button>
                    </div>

                    <div className="accordion-collapse collapse show" id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body ">
                        <p>
                          {" "}
                          <img className="tutor_profile_img" src="teacher-mk/foto-sushi-6anudmpILw4-unsplash.jpg" alt="teacher-image" />
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem ipsum dolor sit
                          amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                        </p>
                      </div>
                      <p className="teacher_detail">
                        <i className="fa-solid fa-location-dot"></i> <p> Pakistan</p>
                        <i className="fa-solid fa-dollar-sign"></i>
                        <p>36–60/week</p>
                        <i className="fa-solid fa-laptop"></i>
                        <p>2 year</p>
                        <i className="fa-solid fa-chalkboard-user"></i>
                        <p>5 year</p>
                      </p>{" "}
                    </div>
                  </div>
                </div>
                {/* FAQ Accordion 2*/}
                <NavLink to="/tutor-profile">
                  {" "}
                  <h4 className="fw-bolder mb-3 teacher_name">haramn singh</h4>
                </NavLink>
                <div className="accordion mb-xl-0" id="accordionExample2">
                  <div className="accordion-item">
                    <h3 className="accordion-header d-flex flex-wrap" id="headingOne">
                      <button className="accordion-button" type="button">
                        Accounting{" "}
                      </button>
                      <button className="accordion-button" type="button">
                        Tamil
                      </button>
                    </h3>
                    <div className="accordion-collapse collapse show" id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body ">
                        <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                      </div>
                      <p className="teacher_detail">
                        <i className="fa-solid fa-location-dot"></i> <p> Pakistan</p>
                        <i className="fa-solid fa-dollar-sign"></i>
                        <p>36–60/week</p>
                        <i className="fa-solid fa-laptop"></i>
                        <p>2 year</p>
                        <i className="fa-solid fa-chalkboard-user"></i>
                        <p>5 year</p>
                      </p>{" "}
                    </div>
                  </div>
                </div>
                <br></br>
                <br></br>

                <NavLink to="/tutor-profile">
                  {" "}
                  <h4 className="fw-bolder mb-3 teacher_name">kirandeep kaur</h4>
                </NavLink>
                <div className="accordion mb-5" id="accordionExample">
                  <div className="accordion-item">
                    <h3 className="accordion-header d-flex flex-wrap" id="headingOne">
                      <button className="accordion-button" type="button">
                        Maths
                      </button>
                      <button className="accordion-button" type="button">
                        Accounting{" "}
                      </button>
                    </h3>
                    <div className="accordion-collapse collapse show" id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body ">
                        <p>
                          {" "}
                          <img className="tutor_profile_img" src="teacher-mk/christina-wocintechchat-com-0Zx1bDv5BNY-unsplash.jpg" alt="teacher-image" />
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem ipsum dolor sit
                          amet, consectetur adipiscing elit,
                        </p>
                      </div>
                      <p className="teacher_detail">
                        <i className="fa-solid fa-location-dot"></i> <p> Pakistan</p>
                        <i className="fa-solid fa-dollar-sign"></i>
                        <p>36–60/week</p>
                        <i className="fa-solid fa-laptop"></i>
                        <p>2 year</p>
                        <i className="fa-solid fa-chalkboard-user"></i>
                        <p>5 year</p>
                      </p>{" "}
                    </div>
                  </div>
                </div>

                <NavLink to="/tutor-profile">
                  {" "}
                  <h4 className="fw-bolder mb-3 teacher_name">manpreet kaur dhaliwal</h4>
                </NavLink>
                <div className="accordion mb-5" id="accordionExample">
                  <div className="accordion-item">
                    <h3 className="accordion-header  d-flex flex-wrap" id="headingOne">
                      <button className="accordion-button" type="button">
                        GMAT
                      </button>
                    </h3>
                    <div className="accordion-collapse collapse show" id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body ">
                        <p>
                          {" "}
                          <img className="tutor_profile_img" src="teacher-mk/michael-damnsplash.jpg" alt="teacher-image" />
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem ipsum dolor sit
                          amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                        </p>
                      </div>
                      <p className="teacher_detail">
                        <i className="fa-solid fa-location-dot"></i> <p> Pakistan</p>
                        <i className="fa-solid fa-dollar-sign"></i>
                        <p>36–60/week</p>
                        <i className="fa-solid fa-laptop"></i>
                        <p>2 year</p>
                        <i className="fa-solid fa-chalkboard-user"></i>
                        <p>5 year</p>
                      </p>{" "}
                    </div>
                  </div>
                </div>

                <NavLink to="/tutor-profile">
                  {" "}
                  <h4 className="fw-bolder mb-3 teacher_name">gurpreet singh</h4>
                </NavLink>
                <div className="accordion mb-5" id="accordionExample">
                  <div className="accordion-item ">
                    <h3 className="accordion-header  d-flex flex-wrap" id="headingOne">
                      <button className="accordion-button" type="button">
                        Cryptocurrency
                      </button>
                    </h3>
                    <div className="accordion-collapse collapse show" id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body ">
                        <p>
                          {" "}
                          <img className="tutor_profile_img" src="teacher-mk/closeup-senior-lecturer-with-arms-crossed.jpg" alt="teacher-image" />
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem ipsum dolor sit
                          amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                        </p>
                      </div>
                      <p className="teacher_detail">
                        <i className="fa-solid fa-location-dot"></i> <p> Pakistan</p>
                        <i className="fa-solid fa-dollar-sign"></i>
                        <p>36–60/week</p>
                        <i className="fa-solid fa-laptop"></i>
                        <p>2 year</p>
                        <i className="fa-solid fa-chalkboard-user"></i>
                        <p>5 year</p>
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 res-mt-2">
                <div className="card border-0 mt-xl-5">
                  <div className="card-body p-4 py-lg-5">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="text-center">
                        <div className="h3 fw-bolder">Location</div>
                      </div>
                    </div>
                    <ul className="list-group list-group-flush country_list">
                      <li className="list-group-item">Afghanistan</li>
                      <li className="list-group-item">Australia</li>
                      <li className="list-group-item">Albania</li>
                      <li className="list-group-item">Algeria</li>
                      <li className="list-group-item">Angola</li>
                      <li className="list-group-item">Anguilla</li>
                      <li className="list-group-item">Argentina</li>
                      <li className="list-group-item">Armenia</li>
                      <li className="list-group-item">Australia</li>
                      <li className="list-group-item">Austria</li>
                      <li className="list-group-item">Azerbaijan</li>
                      <li className="list-group-item">Bahrain</li>
                      <li className="list-group-item">Bangladesh</li>
                      <li className="list-group-item">Belarus</li>
                      <li className="list-group-item">Belgium</li>
                      <li className="list-group-item">Bermuda</li>
                      <li className="list-group-item">Botswana</li>
                      <li className="list-group-item">Brazil</li>
                      <li className="list-group-item">Brunei</li>
                      <li className="list-group-item">Burundi</li>
                      <li className="list-group-item">Cambodia</li>
                      <li className="list-group-item">Cameroon</li>
                      <li className="list-group-item">Canada</li>
                      <li className="list-group-item">Cayman Islands</li>
                      <li className="list-group-item">Bangladesh</li>
                      <li className="list-group-item">Belarus</li>
                      <li className="list-group-item">Belgium</li>
                      <li className="list-group-item">Bermuda</li>
                      <li className="list-group-item">Botswana</li>
                      <li className="list-group-item">Brazil</li>
                      <li className="list-group-item">Brunei</li>
                      <li className="list-group-item">Burundi</li>
                      <li className="list-group-item">Cambodia</li>
                      <li className="list-group-item">Cameroon</li>
                      <li className="list-group-item">Canada</li>
                      <li className="list-group-item">Cayman Islands</li>
                      <li className="list-group-item">Bangladesh</li>
                      <li className="list-group-item">Belarus</li>
                      <li className="list-group-item">Belgium</li>
                      <li className="list-group-item">Bermuda</li>
                      <li className="list-group-item">Botswana</li>
                      <li className="list-group-item">Brazil</li>
                      <li className="list-group-item">Brunei</li>
                      <li className="list-group-item">Burundi</li>
                      <li className="list-group-item">Cambodia</li>
                      <li className="list-group-item">Cameroon</li>
                      <li className="list-group-item">Canada</li>
                      <li className="list-group-item">Cayman Islands</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
            <li className="page-item disabled" style={{ marginLeft: "16px" }}>
              <a className="page-link">Previous</a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                Next
              </a>
            </li>
          </ul>
        </nav>
      </main>
    </>
  );
};

export default Findtutor;
