import React from "react";
import { NavLink } from "react-router-dom";
const StudentClient = () => {
  return (
    <>
      <div className="container EmailVarified-cls Subjects_you_Teach Student_Client">
        <div className="text-center mt-3">
          <p>You haven't posted any requirements yet.</p>
          <button type="button" className="btn btn-primary mt-5">
            {" "}
            Post a requirement
          </button>
          <p className="my-4">or</p>
          <button type="button" className="btn btn-secondary">
            {" "}
            Find teachers
          </button>
        </div>
      </div>
    </>
  );
};

export default StudentClient;
