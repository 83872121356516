import React, { useState } from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import axios from "../../settings/axiosConfig.jsx";
import { getOptions } from "../../settings/options.jsx";
import { Link } from 'react-router-dom';



const Address = () => {
   
const accessToken = localStorage.getItem('accessToken');
	// Set the Authorization header with the token
	axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

	// For fetching location
	const [query, setQuery] = useState("");
	const [searchResults, setSearchResults] = useState([]);

	const [subjects, setSubjects] = useState([]);

	// GET SUBJECTS STARTS
	useEffect(() => {
		axios.get('/subjects')
			.then((response) => {
				if (response.data.success) {
					setSubjects(response.data.data);
				}
			})
			.catch((error) => {
			});
	}, []);
	// GET SUBJECTS ENDS


	const handleSearch = async () => {
		try {
			const apiKey = "d95a1ee8e3334891b29100237231110";
			const apiUrl = `https://api.weatherapi.com/v1/search.json?key=${apiKey}&q=${query}`;

			console.log("API URL:", apiUrl);

			const response = await fetch(apiUrl);
			console.log("API Response:", response);

			const data = await response.json();
			console.log("API Data:", data);

			setTimeout(() => {
				let country = data.country
			}, 3000)

			// Check if 'locations' array exists in the response
			const locations = data || [];

			if (locations.length > 0) {
				setSearchResults(locations);
			} else {
				console.warn("No locations found in the API response.");
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};


	const handleLocationInputChange = (event) => {
		const newQuery = event.target.value;
		setQuery(newQuery);

		// Perform search on each input change
		handleSearch();
	};


	// ========== GET OPTIONS STARTS ========== 
	const [options, setOptions] = useState({
		gender: [],
		budget: [],
		fee_type: [],
		employment_status: [],
		level: [],
		degree_type: [],
		tutor_educational_association: [],
		tutor_experience_association: []
	});

	useEffect(() => {
		const optionNames = ["gender", "degree_type", "tutor_educational_association", "tutor_experience_association", "budget", "fee_type", "employment_status", "level"];

		getOptions(optionNames)
			.then(result => {
				setOptions(result);
			})
			.catch(error => {
				// Handle the error
			});
	}, []);

	console.log(options);

	// ========== GET OPTIONS ENDS ========== 


	// Profile Information
	const [basicFormData, setBasicFormData] = useState({
		strength: "",
		gender: "",
		date_of_birth: ""
	});

	const [basicErrorMessages, setBasicErrorMessages] = useState(false);
	const [basicSuccessMessage, setBasicSuccessMessage] = useState(false);

	const handleBasicInfoChange = (e) => {
		setBasicFormData({ ...basicFormData, [e.target.name]: e.target.value });
	};


	// Location Information
	const [locationFormData, setLocationFormData] = useState({
		country_id: "",
		city_name: "",
		state_name: "",
	});

	const [tutorSubjectData, setTutorSubjectData] = useState({
		from_level_id: "",
		to_level_id: "",
		subject_id: ""
	});

	const [locationErrorMessages, setLocationErrorMessages] = useState(false);
	const [locationSuccessMessage, setLocationSuccessMessage] = useState(false);

	const handleLocationChange = (e) => {
		setLocationFormData({ ...locationFormData, [e.target.name]: e.target.value });
	};

	const handleSubjectChange = (e) => {
		setTutorSubjectData({ ...tutorSubjectData, [e.target.name]: e.target.value });
	};


	// Charges Information
	const [chargesFormData, setChargesFormData] = useState({
		fee_type_id: "",
		fee_currency: "",
		min_charge_in_tutor_currency: "",
		max_charge_in_tutor_currency: "",
		fee_vary_detail: "",
		years_of_experience: "",
		have_digital_pen: "",
		help_with_hw_assignment: "",
		currently_full_time_status: "",
		looking_for_employment_status: "",
	});

	const [chargesErrorMessages, setChargesErrorMessages] = useState(false);
	const [chargesSuccessMessage, setChargesSuccessMessage] = useState(false);

	const handleChargesChange = (e) => {
		setChargesFormData({ ...chargesFormData, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		axios.get('/users/profile')
			.then((response) => {
				const tutorInfo = response.data.data;

				if (tutorInfo.tutor_information) {
					setBasicFormData({
						strength: tutorInfo.tutor_information.strength,
						gender: tutorInfo.gender,
						date_of_birth: tutorInfo.date_of_birth
					})

				}
				if (tutorInfo.tutor_location) {
					setLocationFormData(tutorInfo.tutor_location);
				}

				if (tutorInfo.charges) {
					setChargesFormData(tutorInfo.charges);
				}

				if (tutorInfo.tutor_subjects) {
					setTutorSubjectData(tutorInfo.tutor_subjects);
				}

				// setSubjectData(tutorInfo.tutor_subjects);
			})
			.catch((error) => {
				console.error('Error fetching user profile:', error);
			});
	}, []);

	const handleTutorProfile = async (e, formSection) => {
		e.preventDefault();
		try {

			switch (formSection) {
				case 'basicInfo':
					const metaResponse = await axios.post(`tutor-meta`, basicFormData);
					const profileResponse = await axios.put(`users/profile`, basicFormData);
					console.log(profileResponse);
					break;

				case 'subjects':
					const subjectResponse = await axios.post(`tutor-subject`, tutorSubjectData);
					console.log(subjectResponse)
					break;
				case 'charges':
					const chargesResponse = await axios.post(`tutor-charges`, chargesFormData);
					break;
				default:
					break;
			}

		} catch (error) {
		}
	};


	useEffect(() => {
		$(document).ready(function () {
			var current_fs, next_fs, previous_fs; //fieldsets
			var opacity;

			$(".next").click(function () {
				current_fs = $(this).parent();
				next_fs = $(this).parent().next();

				//Add Class Active
				$("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

				//show the next fieldset
				next_fs.show();
				//hide the current fieldset with style
				current_fs.animate(
					{ opacity: 0 },
					{
						step: function (now) {
							// for making fielset appear animation
							opacity = 1 - now;

							current_fs.css({
								display: "none",
								position: "relative",
							});
							next_fs.css({ opacity: opacity });
						},
						duration: 600,
					}
				);
			});

			$(".previous").click(function () {
				current_fs = $(this).parent();
				previous_fs = $(this).parent().prev();

				//Remove class active
				$("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

				//show the previous fieldset
				previous_fs.show();

				//hide the current fieldset with style
				current_fs.animate(
					{ opacity: 0 },
					{
						step: function (now) {
							// for making fielset appear animation
							opacity = 1 - now;

							current_fs.css({
								display: "none",
								position: "relative",
							});
							previous_fs.css({ opacity: opacity });
						},
						duration: 600,
					}
				);
			});

			$(".radio-group .radio").click(function () {
				$(this).parent().find(".radio").removeClass("selected");
				$(this).addClass("selected");
			});

			$(".submit").click(function () {
				return false;
			});
		});
	}, []);

  return (
   <>
    <div>
	<div id="msform">
										{/* progressbar */}
										<ul id="progressbar">
											<li className="active" id="account">
												<strong>Your basic info</strong>
											</li>
											<li className="active" id="personal">
												<strong>Address </strong>
											</li>
											<li id="payment">
												<strong>Subjects you Teach</strong>
											</li>
											<li id="confirm">
												<strong>Education</strong>
											</li>
											<li id="confirm1">
												<strong>Professional</strong>
											</li>
											<li id="TeachingDetails">
												<strong>Teaching Details</strong>
											</li>
											<li id="ProfileDescription">
												<strong>Profile Description</strong>
											</li>
										</ul>
										

									</div>
   


	<fieldset className="Subjects_you_Teach mx-auto">
											<div className="form-card">
												{/* <h2 className="fs-title">Address (Publicly visible)</h2> */}
												<div className="mb-4">
													<label htmlFor="mail">Location</label>
													<input className="form-control" type="text" onChange={handleLocationInputChange} />
													{searchResults.length > 0 && (
														<ul>
															{searchResults.map((location) => (
																<li key={location.id}>
																	{location.name}, {location.region}, {location.country}
																</li>
															))}
														</ul>
													)}
												</div>

												<div className="mb-4">
													<label htmlFor="text">Postal code</label>
													<input className="form-control" type="text" />
												</div>

												<div className="mb-4">
													<label htmlFor="tel" maxLength="10" className="js-input-mobile">
														Phone
													</label>
													<input className="form-control" type="text" />
												</div>
											</div>

											{/*  */}
{/* <input type="button" name="previous" className="previous action-button-previous btn btn-outline-secondary" defaultValue="Previous" />
											<input type="button" name="next" className="next action-button btn btn-primary ms-4" defaultValue="Next Step" />										 */}
	<Link to="/SFP/basic">
	<input type="button" name="previous" className="previous action-button-previous btn btn-outline-secondary" defaultValue="Previous" />
	</Link>
	<Link to="/SFP/subject">
	<input type="button" name="next" className="next action-button btn btn-primary ms-4" defaultValue="Next Step" />
	</Link>
</fieldset>
	</div>
                       
     
   </>
  )
}

export default Address