import React from "react";
import { NavLink } from "react-router-dom";

const VerifyEmail = () => {
  return (
    <>
      <section className="pt-5 pb-3">
        <div className="container">
          <div className="verify_email_page text-center">
            <h3>Check your inbox to verify email</h3>

            <p>
              We've sent an email to <b>narabhit5kwt@gmail.com</b>
              <br />
              Follow the instructions to verify your email address
            </p>
            <img src="teacher-mk/372829-PBsdsdLJIB-80.jpg" alt="Email victor" />
            <br />
            <a href="#">I have not received mail</a>
          </div>
        </div>
        <NavLink to="/VE" className="d-flex justify-content-center mt-3">
          {" "}
          <div className="text-center mt-3">
            <button type="button" className=" btn btn-primary px-5 ">
              Next
            </button>
          </div>
        </NavLink>
      </section>
    </>
  );
};

export default VerifyEmail;
