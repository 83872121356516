import React, { useState } from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import axios from "../../settings/axiosConfig.jsx";
import { getOptions } from "../../settings/options.jsx";
import { Link } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { useNavigate } from 'react-router-dom';





const YourBasicInfo = () => {


	const handleBasicInfoChange = (e) => {
		setBasicFormData({ ...basicFormData, [e.target.name]: e.target.value });
	};



	const [basicFormData, setBasicFormData] = useState({
		strength: "",
		gender: "",
		date_of_birth: ""
	});
	const [locationFormData, setLocationFormData] = useState({
		country_id: "",
		city_name: "",
		state_name: "",
	});
	const handleLocationChange = (e) => {
		setLocationFormData({ ...locationFormData, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		axios.get('/users/profile')
			.then((response) => {
				const tutorInfo = response.data.data;

				if (tutorInfo.tutor_information) {
					setBasicFormData({
						strength: tutorInfo.tutor_information.strength,
						gender: tutorInfo.gender,
						date_of_birth: tutorInfo.date_of_birth
					})

				}
				if (tutorInfo.tutor_location) {
					setLocationFormData(tutorInfo.tutor_location);
				}

				if (tutorInfo.charges) {
					setChargesFormData(tutorInfo.charges);
				}

				if (tutorInfo.tutor_subjects) {
					setTutorSubjectData(tutorInfo.tutor_subjects);
				}

				// setSubjectData(tutorInfo.tutor_subjects);
			})
			.catch((error) => {
				console.error('Error fetching user profile:', error);
			});
	}, []);

	const handleChargesChange = (e) => {
		setChargesFormData({ ...chargesFormData, [e.target.name]: e.target.value });
	};
	const [options, setOptions] = useState({
		gender: [],
		budget: [],
		fee_type: [],
		employment_status: [],
		level: [],
		degree_type: [],
		tutor_educational_association: [],
		tutor_experience_association: []
	});
	useEffect(() => {
		const optionNames = ["gender", "degree_type", "tutor_educational_association", "tutor_experience_association", "budget", "fee_type", "employment_status", "level"];

		getOptions(optionNames)
			.then(result => {
				setOptions(result);
			})
			.catch(error => {
				// Handle the error
			});
	}, []);

	console.log(options);
	const [chargesFormData, setChargesFormData] = useState({
		fee_type_id: "",
		fee_currency: "",
		min_charge_in_tutor_currency: "",
		max_charge_in_tutor_currency: "",
		fee_vary_detail: "",
		years_of_experience: "",
		have_digital_pen: "",
		help_with_hw_assignment: "",
		currently_full_time_status: "",
		looking_for_employment_status: "",
	});



	const handleSubjectChange = (e) => {
		setTutorSubjectData({ ...tutorSubjectData, [e.target.name]: e.target.value });
	};

	const [tutorSubjectData, setTutorSubjectData] = useState({
		from_level_id: "",
		to_level_id: "",
		subject_id: ""
	});


	const navigate = useNavigate();

	const handleTutorProfile = async (e, formSection) => {
		e.preventDefault();
		try {

			switch (formSection) {
				case 'basicInfo':
					const metaResponse = await axios.post(`tutor-meta`, basicFormData);
					const profileResponse = await axios.put(`users/profile`, basicFormData);
					console.log(profileResponse);
					break;

				case 'subjects':
					const subjectResponse = await axios.post(`tutor-subject`, tutorSubjectData);
					console.log(subjectResponse)
					break;
				case 'charges':
					const chargesResponse = await axios.post(`tutor-charges`, chargesFormData);
					break;
				default:
					break;
			}
			navigate('/SFP/address');
		} catch (error) {
		}
	};

	return (
		<>
<div>
<div id="msform">
										<ul id="progressbar">
											<li className="active" id="account">
												<strong>Your basic info</strong>
											</li>
											<li id="personal">
												<strong>Address </strong>
											</li>
											<li id="payment">
												<strong>Subjects you Teach</strong>
											</li>
											<li id="confirm">
												<strong>Education</strong>
											</li>
											<li id="confirm1">
												<strong>Professional</strong>
											</li>
											<li id="TeachingDetails">
												<strong>Teaching Details</strong>
											</li>
											<li id="ProfileDescription">
												<strong>Profile Description</strong>
											</li>
										</ul>
										

									</div>

			<div className="Subjects_you_Teach mx-auto">
				<div className="login_first_step">

					<div className="mb-4">
						<label className="form-label" htmlFor="strength">
							Speciality, Strength or Current role{" "}
						</label>
						<input type="text" id="strength" name="strength" onChange={handleBasicInfoChange} value={basicFormData.strength} className="form-control" />
					</div>

					<div className="mb-4">
						<label className="form-label" htmlFor="gender">
							Gender
						</label>
						<select id="gender" name="gender" className="form-select" value={basicFormData.gender} onChange={handleBasicInfoChange}>
							<option>Select Gender</option>
							{options.gender.map((opt, index) => (
								<option key={index} value={opt.id}>
									{opt.option}
								</option>
							))}
						</select>
					</div>

					<div className="mb-4">
						<label className="form-label" htmlFor="birthdate">
							Birth Date
						</label>
						<input className="form-control mb-4" id="birthdate" type="date" name="date_of_birth" onChange={handleBasicInfoChange} value={basicFormData.date_of_birth} />
					</div>

				</div>

				{/* <Link to="/SFP/address"> */}
					<input type="button" name="next" className="next action-button btn btn-primary mb-4" defaultValue="Next Step" 
					onClick={(e) => handleTutorProfile(e, 'basicInfo')} />
				{/* </Link> */}
			</div>
			</div>
		</>
	)
}

export default YourBasicInfo