import React, { useState } from "react";
import { useEffect } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
// import "../../sim.css";
import $ from "jquery";
import axios from "../../settings/axiosConfig.jsx";
import { getOptions } from "../../settings/options.jsx";
import SubjectTeach from "../Teacher Form/SubjectTeach.js";
import Profile_Description from "./Profile Description.js";
import TeachingDetails from "./TeachingDetails2.js";
import YourBasicInfo from "./YourBasicInfo.js";
import Address from "./Address.js";
import SubYouTeach from "./SubYouTeach.js";
import Education from "./Education.js";
import Professional from "./Professional.js";


function Stepform() {

	const accessToken = localStorage.getItem('accessToken');
	// Set the Authorization header with the token
	axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;


	// ========== GET OPTIONS STARTS ========== 
	const [options, setOptions] = useState({
		gender: [],
		budget: [],
		fee_type: [],
		employment_status: [],
		level: [],
		degree_type: [],
		tutor_educational_association: [],
		tutor_experience_association: []
	});

	useEffect(() => {
		const optionNames = ["gender", "degree_type", "tutor_educational_association", "tutor_experience_association", "budget", "fee_type", "employment_status", "level"];

		getOptions(optionNames)
			.then(result => {
				setOptions(result);
			})
			.catch(error => {
				// Handle the error
			});
	}, []);
	// ========== GET OPTIONS ENDS ========== 



	// GET SUBJECTS STARTS
	useEffect(() => {
		axios.get('/subjects')
			.then((response) => {
				if (response.data.success) {
					setSubjects(response.data.data);
				}
			})
			.catch((error) => {
			});
	}, []);
	// GET SUBJECTS ENDS


	// For fetching location
	const [query, setQuery] = useState("");
	const [searchResults, setSearchResults] = useState([]);


	// Profile Information
	const [basicFormData, setBasicFormData] = useState({
		strength: "",
		gender: "",
		date_of_birth: ""
	});

	// Location Information
	const [locationFormData, setLocationFormData] = useState({
		country_id: "",
		city_name: "",
		state_name: "",
	});

	const [tutorSubjectData, setTutorSubjectData] = useState({
		from_level_id: "",
		to_level_id: "",
		subject_id: ""
	});

	const [subjects, setSubjects] = useState([]);
	const [educationData, setEducationData] = useState([]);
	const [professionalData, setProfessionalData] = useState([]);
	const [chargesFormData, setChargesFormData] = useState([]);
	const [profileFormData, setProfileFormData] = useState([]);


	const handleSearch = async () => {
		try {
			const apiKey = "d95a1ee8e3334891b29100237231110";
			const apiUrl = `https://api.weatherapi.com/v1/search.json?key=${apiKey}&q=${query}`;

			console.log("API URL:", apiUrl);

			const response = await fetch(apiUrl);
			console.log("API Response:", response);

			const data = await response.json();
			console.log("API Data:", data);

			setTimeout(() => {
				let country = data.country
			}, 3000)

			// Check if 'locations' array exists in the response
			const locations = data || [];

			if (locations.length > 0) {
				setSearchResults(locations);
			} else {
				console.warn("No locations found in the API response.");
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};


	const handleLocationInputChange = (event) => {
		const newQuery = event.target.value;
		setQuery(newQuery);

		// Perform search on each input change
		handleSearch();
	};


	const [basicErrorMessages, setBasicErrorMessages] = useState(false);
	const [basicSuccessMessage, setBasicSuccessMessage] = useState(false);

	const handleBasicInfoChange = (e) => {
		setBasicFormData({ ...basicFormData, [e.target.name]: e.target.value });
	};

	const handleLocationChange = (e) => {
		setLocationFormData({ ...locationFormData, [e.target.name]: e.target.value });
	};

	const handleSubjectChange = (e) => {
		setTutorSubjectData({ ...tutorSubjectData, [e.target.name]: e.target.value });
	};

	const handleEducationalInfoChange = (e) => {
		setEducationData({ ...educationData, [e.target.name]: e.target.value });
	};

	const handleProfessionInfoChange = (e) => {
		setProfessionalData({ ...professionalData, [e.target.name]: e.target.value });
	};

	const handleChargesChange = (e) => {
		setChargesFormData({ ...chargesFormData, [e.target.name]: e.target.value });
	};

	const handleProfileChange = (e) => {
		setProfileFormData({ ...profileFormData, [e.target.name]: e.target.value });
	};

	console.log(chargesFormData);

	const [locationErrorMessages, setLocationErrorMessages] = useState(false);
	const [locationSuccessMessage, setLocationSuccessMessage] = useState(false);

	const [chargesErrorMessages, setChargesErrorMessages] = useState(false);
	const [chargesSuccessMessage, setChargesSuccessMessage] = useState(false);


	// ==================== FETCH LOGGED IN USER PROFILE ==================== 
	useEffect(() => {
		axios.get('/users/profile')
			.then((response) => {
				const tutorInfo = response.data.data;

				if (tutorInfo.tutor_information) {
					setBasicFormData({
						strength: tutorInfo.tutor_information.strength,
						gender: tutorInfo.gender,
						date_of_birth: tutorInfo.date_of_birth
					})

				}
				if (tutorInfo.tutor_location) {
					setLocationFormData(tutorInfo.tutor_location);
				}

				if (tutorInfo.charges) {
					setChargesFormData(tutorInfo.charges);
				}

				if (tutorInfo.tutor_subjects) {
					setTutorSubjectData(tutorInfo.tutor_subjects);
				}

				// setSubjectData(tutorInfo.tutor_subjects);
			})
			.catch((error) => {
				console.error('Error fetching user profile:', error);
			});
	}, []);


	// ================ HANDLE FORM SUBMISSION ======================
	const handleTutorProfile = async (e, formSection) => {
		e.preventDefault();
		try {

			switch (formSection) {
				case 'basicInfo':
					const metaResponse = await axios.post(`tutor-meta`, basicFormData);
					const profileResponse = await axios.put(`users/profile`, basicFormData);
					break;

				case 'subjects':
					const subjectResponse = await axios.post(`tutor-subject`, tutorSubjectData);
					console.log(subjectResponse)
					break;

				case 'educations':
					const educationResponse = await axios.post(`tutor-education`, educationData);
					console.log(educationResponse)
					break;

				case 'professional':
					const professionalResponse = await axios.post(`tutor-experience`, professionalData);
					console.log(professionalResponse)
					break;

				case 'charges':
					const chargesResponse = await axios.post(`tutor-charges`, chargesFormData);
					break;

				case 'metaInfo':
					const metaProfileResponse = await axios.post(`tutor-meta`, profileFormData);
					break;

				default:
					break;
			}

		} catch (error) {
		}
	};


	useEffect(() => {
		$(document).ready(function () {
			var current_fs, next_fs, previous_fs; //fieldsets
			var opacity;

			$(".next").click(function () {
				current_fs = $(this).parent();
				next_fs = $(this).parent().next();

				//Add Class Active
				$("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

				//show the next fieldset
				next_fs.show();
				//hide the current fieldset with style
				current_fs.animate(
					{ opacity: 0 },
					{
						step: function (now) {
							// for making fielset appear animation
							opacity = 1 - now;

							current_fs.css({
								display: "none",
								position: "relative",
							});
							next_fs.css({ opacity: opacity });
						},
						duration: 600,
					}
				);
			});

			$(".previous").click(function () {
				current_fs = $(this).parent();
				previous_fs = $(this).parent().prev();

				//Remove class active
				$("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

				//show the previous fieldset
				previous_fs.show();

				//hide the current fieldset with style
				current_fs.animate(
					{ opacity: 0 },
					{
						step: function (now) {
							// for making fielset appear animation
							opacity = 1 - now;

							current_fs.css({
								display: "none",
								position: "relative",
							});
							previous_fs.css({ opacity: opacity });
						},
						duration: 600,
					}
				);
			});

			$(".radio-group .radio").click(function () {
				$(this).parent().find(".radio").removeClass("selected");
				$(this).addClass("selected");
			});

			$(".submit").click(function () {
				return false;
			});
		});
	}, []);

	return (
		<>
			<div className="container-fluid stepform-cls" id="grad1">
				<div className="row justify-content-center mt-0">
					<div className="col-11 col-sm-9  p-0 mt-3 mb-2">
						<div className="card px-0 pt-4 pb-0 mt-3 mb-3">
							<h2 className="text-center">
								<strong>Teacher Details</strong>
							</h2>
							<p className="text-center mb-3">Fill all form field to go to next step</p>
							<div className="row">
								<div className="col-md-12 mx-0">
									{/* <div id="msform">
										<ul id="progressbar">
											<li className="active" id="account">
												<strong>Your basic info</strong>
											</li>
											<li id="personal">
												<strong>Address </strong>
											</li>
											<li id="payment">
												<strong>Subjects you Teach</strong>
											</li>
											<li id="confirm">
												<strong>Education</strong>
											</li>
											<li id="confirm1">
												<strong>Professional</strong>
											</li>
											<li id="TeachingDetails">
												<strong>Teaching Details</strong>
											</li>
											<li id="ProfileDescription">
												<strong>Profile Description</strong>
											</li>
										</ul>
										

									</div> */}


<Routes>
	<Route path="basic" element={<YourBasicInfo/>}/>
	<Route path="address" element={<Address/>}/>
	<Route path="subject" element={<SubYouTeach/>}/>
	<Route
            path="education"
            element={<Education />}
          />


          <Route
            path="professional"
            element={<Professional />}
          />


          <Route
            path="teach_detail"
            element={<TeachingDetails />}
          />

          <Route
            path="pro_des"
            element={<Profile_Description />}
          />
	
</Routes>

								</div>
							</div>
							
						</div>
					</div>
				</div >
			</div >
		</>
	);
}

export default Stepform;