import React from "react";
import { NavLink } from "react-router-dom";

const Invitefriends = () => {
  return (
    <>
      <div className="cointainer mt-5">
        <div className="row">
          <div className="col ma Subjects_you_Teach mx-auto text-start">
            <h1 className="pb-5">Invite your friends</h1>

            <div className="alert alert-info  py-4 mb-3 mt-5" role="alert">
              We will put{" "}
              <NavLink to="/ReferandEarn">
                <a href="#" className="text-primary alert-link text-decoration-underline">
                  your referral link
                </a>
              </NavLink>
              in the invite so you get coins when someone you invite joins us.
            </div>

            <NavLink to="">
              {" "}
              <button type="button" className="btn bg-clr-green border mt-3 text-white">
                <img width="47px" src="teacher-mk/image8-2-removebg-preview.png" alt="Google-icon" />
                Invite from Google
              </button>
            </NavLink>

            <NavLink to="">
              {" "}
              <button type="button" className="btn  bg-clr-green border mt-3 text-white">
                <img width="27px" className="mx-3" src="teacher-mk/email_893257.png" alt="Email-icon" />
                Invite with Email
              </button>
            </NavLink>
            <br />
            <br />
            <div className="row gx-3 gy-2 align-items-center">
              <label htmlFor="form-label">Email</label>

              <textarea className="form-control" placeholder="Leave a comment here" rows="8" id="floatingTextarea2"></textarea>
              <p>
                Note: <span style={{ fontSize: "13px", color: "#303030" }}>Please enter all the emails you want to invite.</span>
              </p>
            </div>
            <br />
            <button type="button" className="btn btn-primary px-4">
              Invite friends
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invitefriends;
