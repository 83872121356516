import React, { useEffect, useState } from "react";
import axios from "../../settings/axiosConfig.jsx";

function TutorProfile() {
  const [tutorInfo, setTutorInfo] = useState(null);

  useEffect(() => {
    axios
      .get("/users/profile")
      .then((response) => {
        const tutorInfo = response.data.data;

        setTutorInfo(tutorInfo);
        console.log(tutorInfo);

        console.log(tutorInfo.tutor_subjects);
      })
      .catch((error) => {
        console.error("Error fetching user profile:", error);
      });
  }, []);

  if (!tutorInfo) {
    // You can render a loading indicator or a message here while waiting for data.
    return <p className="text-center">Loading...</p>;
  }

  return (
    <>
      <section className="Teacher_Main_section my-5">
        <div className="container p-xl-0 p-lg-0 p-md-auto">
          <div className="row">
            <div className="col-lg-4 p-0 position-relative tutor_box_img">
              <img className="teacher_Img" src="teacher-mk/closeup-senior-lecturer-with-arms-crossed.jpg" alt="Teacher-porfile" />
              <div className="social_box">
                <div className="w-50 h-100 d-inline-flex align-items-center justify-content-center gap-3 left_box_icons">
                  <i className="fa-brands fa-square-facebook"></i>
                  <i className="fa-brands fa-instagram"></i>
                  <i className="fa-brands fa-square-twitter"></i>
                </div>
                <div className="w-50 h-100 d-inline-flex align-items-center justify-content-center gap-4">
                  <i className="fa-solid fa-phone"></i>
                  <p>Reviews</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 ps-xl-5 pe-xl-0 columan">
              <div className="d-flex align-items-center gap-3">
                <div className="color_dot"></div>
                <p className="Teaching_text">{tutorInfo.tutor_information.strength}</p>
              </div>
              <h2 className="fw-bold">
                {tutorInfo.first_name} {tutorInfo.last_name}
              </h2>
              <p>
                Dear Students! <br />
                {tutorInfo.tutor_information.profile_description}
              </p>
            </div>

            <div className="col-lg-4 ps-xl-5 mt-xl-3">
              <div className="tutor_Detail_box">
                <div className="d-flex justify-content-around">
                  <a href="#" className="Box_btn">
                    <div>
                      <i className="fa-solid fa-phone"></i> Phone
                    </div>
                  </a>

                  <a href="#" className="Box_btn">
                    <div>
                      <i className="fa fa-thin fa-envelope-open"></i>
                      Message
                    </div>
                  </a>
                </div>

                <div className="d-flex justify-content-around mt-2">
                  <a href="#" className="Box_btn">
                    <div>
                      <i className="fa-solid fa-phone"></i> Pay
                    </div>
                  </a>

                  <a href="#" className="Box_btn">
                    <div>
                      <i className="fa-solid fa-phone"></i>Review
                    </div>
                  </a>
                </div>

                <div className="tutor_details_sec mt-4 ms-2">
                  <div className="border-bottom-box">
                    <p>
                      <i className="fa fa-thin fa-location-dot"></i>
                      Johrat, Asssa, India
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa fa-thin fa-plane-up"></i>
                      Can travel: {tutorInfo.charges.willing_to_travel_formatted}
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa fa-light fa-right-to-bracket"></i>
                      Last login: {tutorInfo.last_login_formatted}
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa-solid fa-phone"></i>
                      Registered: {tutorInfo.registered_at}
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa-solid fa-person-chalkboard"></i>
                      Total Teaching Exp: {tutorInfo.tutor_information.years_of_experience_formatted}
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa-brands fa-slack"></i>
                      Teaching online: {tutorInfo.charges.available_for_online_teaching_formatted}
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa fa-thin fa-desktop"></i>
                      Online teaching Exp: {tutorInfo.charges.years_of_online_experience} years
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa fa-thin fa-house-chimney"></i>
                      Teaching at student’s home: {tutorInfo.charges.willing_to_travel_formatted}
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa fa-duotone fa-book-open"></i>
                      Homework Help: {tutorInfo.charges.help_with_hw_assignment_formatted}
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa fa-light fa-venus-mars"></i>
                      Gender: {tutorInfo.gender_formatted}
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa fa-light fa-briefcase"></i>
                      Work as: Individual Teacher (PENDING)
                    </p>
                  </div>
                  <div className="border-bottom-box">
                    <p>
                      <i className="fa fa-light fa-credit-card"></i>
                      {tutorInfo.charges.min_charge_in_tutor_currency} - {tutorInfo.charges.max_charge_in_tutor_currency} / {tutorInfo.charges.fee_type} <br /> (US$2.41 hour pending)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-cols-3 last_fourth_box py-3">
              <div className="col sub_detail p-0">
                <div>
                  <h6>Subjects</h6>
                  {tutorInfo.tutor_subjects.map((subjectData, index) => (
                    <p key={index}>
                      <strong>{subjectData.subject}</strong> <br /> ({subjectData.level_from} - {subjectData.level_to})
                    </p>
                  ))}
                </div>
              </div>
              <div className="col sub_detail p-0">
                <div>
                  <h6>Experience</h6>
                  {tutorInfo.tutor_experiences.map((experience, index) => (
                    <div key={index}>
                      <p>
                        <strong>{experience.designation} </strong> <br />({experience.start_month_formatted} {experience.start_year} - {experience.end_month_formatted} {experience.end_year})
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col sub_detail px-xl-4 px-lg-0 p-sm-0 ">
                <div>
                  <h6>Education</h6>
                  {tutorInfo.tutor_educations.map((education, index) => (
                    <div key={index}>
                      <p>
                        <strong>{education.degree_name}</strong> <br /> ( {education.start_month_formatted} {education.start_year}) from {education.institute}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TutorProfile;
