import React from 'react'
import { NavLink } from 'react-router-dom'



const Coin = () => {
  return (
    <>
    <div className='cointainer mt-5'>
        <div className='row'>
            <div className='col ma'>
                <h1 className='mb-0'>Coin Wallet</h1>
                <p>Current Balance : 0 coins</p>
               <NavLink to="/GetCoins"> <button type="button" className='btn  bg-clr-green border mt-3 text-white'>Buy coins</button></NavLink>
            </div>
        </div>
    </div>    
    </>
  )
}

export default Coin