import React from "react";
import { NavLink } from "react-router-dom";
const AfterVerifytutor = () => {
  return (
    <>
      <div className="container EmailVarified-cls Subjects_you_Teach">
        <div className="row border mt-5  ma EmailVarified-cls">
          <div className="col-10" style={{ padding: "0" }}>
            <div className="w-100 icon-full-div">
              <div className="bg-clr-green h-100 icon-div position-relative">
                <div className="skew-div position-absolute h-100 position-absolute"></div>
                <i className="fa-solid fa-check " style={{ color: "white", fontSize: "36px", margin: "5px" }}></i>
              </div>
              <p className="verification-text" style={{ paddingTop: "0", marginLeft: "20px" }}>
                Email varified
              </p>
            </div>
          </div>
          <div className="col-2">
            <i className="fa-solid fa-delete-left fa-2xl" style={{ marginTop: "22px", float: "right" }}></i>
          </div>
        </div>
        <div className="mt-5 EmailVarified-cls ">
          <label htmlFor="validationCustom02" className="form-label mt-2">
            I am a
          </label>
          <select className="form-select" aria-label="Default select example">
            <option selected>Please select</option>
            <option value="1">Student</option>
            <option value="2">Parent/Guardian</option>
            <option value="3">Professional</option>
            <option value="4">Company/Mediator</option>
          </select>

          <div className="text-start">
            <NavLink to="/Tutorphoneverify">
              {" "}
              <div className="btn border mt-5 ma bg-clr-green after_email_btn Post_requirement">Submit</div>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default AfterVerifytutor;
