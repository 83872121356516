import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import { Login } from "./Login.js";
import { Register } from "./Register.js";

const LoginPage = () => {
  return (
    <>
      <section className="py-5 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="Login_teacher_profile_pop text-center">
                <h3>
                  <span>Login</span>
                  <span>Join</span>
                </h3>
                <a href="#">
                  {/* <i className="fa-brands fa-google fa-lg"></i>  */}
                  <img width="57px" src="teacher-mk/image8-2-removebg-preview.png" alt="Google-icon" />
                  <span> Sign in with Google</span>
                </a>
                <br />
                <p className="my-4">or</p>
                <div className="join_login_btn d-flex gap-3 justify-content-center">
                  <a id="hide" className="loginactive">
                    {" "}
                    Login
                  </a>
                  <a id="show"> Join</a>
                </div>
              </div>
              <div className="d-flex justify-content-center my-5 w-100 mb-0">
                <Login />
                <Register />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default LoginPage;
