import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../../sim.css";
import $ from "jquery";
import axios from "../../settings/axiosConfig.jsx";
import { toast } from "react-toastify";
import { getOptions } from "../../settings/options.jsx";

function RequestTutor() {

	console.log(localStorage.getItem('accessToken'));
	const [query, setQuery] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [subjects, setSubjects] = useState([]);
	const [showDropdown, setShowDropdown] = useState(false);

	const navigate = useNavigate();

	// GET OPTIONS
	const [options, setOptions] = useState({
		level: [],
		type_of_help: [],
		meeting_options: [],
		budget: [],
		gender_preference: []
	});

	useEffect(() => {
		const optionNames = ["level", "type_of_help", "meeting_options", "budget", "gender_preference"];

		getOptions(optionNames)
			.then(result => {
				setOptions(result);
			})
			.catch(error => {
				// Handle the error
			});
	}, []);


	// GET SUBJECTS
	useEffect(() => {
		axios.get('/subjects')
			.then((response) => {
				if (response.data.success) {
					setSubjects(response.data.data);
				}
			})
			.catch((error) => {
			});
	}, []);


	const [location, setLocation] = useState({
		name: "",
		country: "",
		lat: "",
		lon: "",
		region: "",
		country_code: "",
		country_id: "",
		state_id: "",
		city_id: "",
		city: ""
	});

	// Register api
	const [postData, setPostData] = useState({});

	// const [errorMessages, setErrorMessages] = useState({});
	// const [successMessage, setSuccessMessage] = useState(false);

	const handleSearch = async () => {
		try {
			const apiKey = "d95a1ee8e3334891b29100237231110";
			const apiUrl = `https://api.weatherapi.com/v1/search.json?key=${apiKey}&q=${query}`;

			// console.log("API URL:", apiUrl);

			const response = await fetch(apiUrl);
			// console.log("API Response:", response);

			const data = await response.json();
			// console.log("API Data:", data);

			// Check if 'locations' array exists in the response
			const locations = data || [];

			if (locations.length > 0) {
				setSearchResults(locations);
			} else {
				// console.warn("No locations found in the API response.");
			}
		} catch (error) {
			// console.error("Error fetching data:", error);
		}
	};

	const handleInputChange = (event) => {
		const newQuery = event.target.value;
		setQuery(newQuery);

		// Perform search on each input change
		handleSearch();
	};


	const [travelToTutor, setTravelToTutor] = useState(false);

	const handleCheckboxChange = () => {
		setTravelToTutor(!travelToTutor);
	};


	const handleLocationClick = async (location) => {
		try {
		  const locationResponse = await axios.post(`locations/country-name/India`, {
			country: location.country,
			region: location.region,
			city: location.name,
			lat: location.lat,
			lon: location.lon
		  });
	  
		  if (locationResponse.data) {
			const data = locationResponse.data.data;
	  
			setLocation({
			  country_id: data.country.id,
			  region: data.state.name,
			  city: data.city.name,
			  lat: data.city.latitude,
			  lon: data.city.longitude,
			  city_id: data.city.id,
			  state_id: data.state.id,
			  countrycode: data.country.phonecode
			});
	  
			setPostData({
			  country_id: data.country.id,
			  state_id: data.state.id,
			  city_id: data.city.id,
			  budget_currency: data.country.currency,
			  country_code: data.country.phonecode
			});
	  
			// Construct the formatted location string, handling undefined values
			const formattedLocation = [
			  data.city.name,
			  data.state ? data.state.name : 'Undefined Region',
			  data.country ? data.country.name : 'Undefined Country'
			].join(', ');
	  
			// Set the query state to the formatted location string
			setQuery(formattedLocation);
		  }
		} catch (error) {
		  console.error(error);
		  // Handle errors if needed
		}
		setShowDropdown(false);
	  };
	  

	// console.log(location);

	// STEP 1

	// Profile Information
	const [basicFormData, setBasicFormData] = useState({
		name: "",
		email: "",
		user_type: "",
		user_id: ""
	});

	const [errorMessages, setErrorMessages] = useState(false);
	const [basicSuccessMessage, setBasicSuccessMessage] = useState(false);

	const handleBasicInfoChange = (e) => {
		setBasicFormData({ ...basicFormData, [e.target.name]: e.target.value });
	};

	const handlePostInfoChange = (e) => {
		setPostData({ ...postData, [e.target.name]: e.target.value });
	};

	const handleRequestTutor = async (e, formSection) => {
		e.preventDefault();
		try {
			console.log(postData);

			switch (formSection) {
				case 'basicInfo':
					try {
						const basicResponse = await axios.post(`users/email-exists`, basicFormData);
						if (basicResponse.data.success === true) {
							console.log(basicResponse.data.data.id);
							setBasicSuccessMessage(basicResponse.data.message);
							setBasicFormData({ ...basicFormData, user_id: basicResponse.data.data.id })
						}
					}
					catch (error) {
						console.log(error.basicResponse);
						setErrorMessages(error.basicResponse.data.data);
					}
					break;

				case 'postSubmit':
					try {
						setPostData({ ...postData, user_id: basicFormData.user_id });

						console.log(postData);

						const postResponse = await axios.post(`posts`, postData);
						console.log(postResponse);
						if (postResponse.data.success === true) {
							// console.log(postResponse.data.success);
							setBasicSuccessMessage(postResponse.data.message);
							toast.success(postResponse.data.message, { autoClose: 2000 });
						}
					}
					catch (error) {
						console.log(error);
						setErrorMessages(error.response.data.data);
						toast.error(error.response.data.message, { autoClose: 2000 });

						// navigate("/Verifytutor");

					}
					break;
				default:
					break;
			}

		} catch (error) {
		}
	};



	console.log(postData);

	useEffect(() => {
		$(document).ready(function () {
			var current_fs, next_fs, previous_fs; //fieldsets
			var opacity;

			$(".next").click(function () {
				current_fs = $(this).parent();
				next_fs = $(this).parent().next();

				//Add Class Active
				$("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

				//show the next fieldset
				next_fs.show();
				//hide the current fieldset with style
				current_fs.animate(
					{ opacity: 0 },
					{
						step: function (now) {
							// for making fielset appear animation
							opacity = 1 - now;

							current_fs.css({
								display: "none",
								position: "relative",
							});
							next_fs.css({ opacity: opacity });
						},
						duration: 600,
					}
				);
			});

			$(".previous").click(function () {
				current_fs = $(this).parent();
				previous_fs = $(this).parent().prev();

				//Remove class active
				$("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

				//show the previous fieldset
				previous_fs.show();

				//hide the current fieldset with style
				current_fs.animate(
					{ opacity: 0 },
					{
						step: function (now) {
							// for making fielset appear animation
							opacity = 1 - now;

							current_fs.css({
								display: "none",
								position: "relative",
							});
							previous_fs.css({ opacity: opacity });
						},
						duration: 600,
					}
				);
			});

			$(".radio-group .radio").click(function () {
				$(this).parent().find(".radio").removeClass("selected");
				$(this).addClass("selected");
			});

			$(".submit").click(function () {
				return false;
			});
		});
	}, []);

	return (
		<>
			<div className="container-fluid stepform-cls" id="grad1">
				<div className="row justify-content-center mt-0">
					<div className="col-11 col-sm-9  p-0 mt-3 mb-2">
						<div className="card px-0 pt-4 pb-0 mt-3 mb-3">
							<h2 className="text-center">
								<strong>Request a tutor</strong>
							</h2>
							<p className="text-center">Fill all form field to go to next step</p>
							<div className="row">
								<div className="col-md-12 mx-0">
									<div id="msform">
										{/* progressbar */}

										{/* fieldsets */}


										<fieldset className="Subjects_you_Teach mx-lg-auto">
											<form className="login_first_step  needs-validation" noValidate>
												<label htmlFor="name">Name</label>
												<input className="form-control" name="name" type="text" id="name" onChange={handleBasicInfoChange} value={basicFormData.name} />
												{errorMessages["name"] && (<div className="text-danger pt-2">{errorMessages["name"][0]}</div>)}
												<br></br>
												<label htmlFor="email">Email</label>
												<input className="form-control" type="text" name="email" id="email" onChange={handleBasicInfoChange} value={basicFormData.email} />
												{errorMessages["email"] && (<div className="text-danger pt-2">{errorMessages["email"][0]}</div>)}
											</form>
											<input type="button" name="next" className="next action-button btn btn-primary Post_requirement" defaultValue="Next Step" onClick={(e) => handleRequestTutor(e, 'basicInfo')} />
										</fieldset>

										<fieldset className="Subjects_you_Teach mx-lg-auto">
											<div className="login_first_step  needs-validation">
											<label htmlFor="location">Location</label>
                        <div className="location-dropdown-container">
                          <input
                            type="text"
                            className="form-control"
                            value={query}
                            id="location"
                            onChange={handleInputChange}
                            onFocus={() => setShowDropdown(true)}
                          />
                          {showDropdown && searchResults.length > 0 && (
                            <ul className="location-dropdown">
                              {searchResults.map((location) => (
                                <li key={location.id} onClick={() => handleLocationClick(location)}>
                                  {location.name}, {location.region}, {location.country}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>


												<br></br>

												<label htmlFor="phone">Phone</label>
												<input className="form-control" type="text" name="post_phone" id="phone" onChange={handlePostInfoChange} />
												{errorMessages["post_phone"] && (<div className="text-danger pt-2">{errorMessages["post_phone"][0]}</div>)}
												<br></br>


												<label className="form-label" htmlFor="title">
													Title
												</label>
												<input type="text" name="title" id="title" onChange={handlePostInfoChange} className="form-control" />

												<br />

												<label className="form-label" htmlFor="details">
													Details of your requirement
												</label>
												<br />
												<textarea className="form-control" name="details" cols="30" rows="10" onChange={handlePostInfoChange}>{basicFormData.details}</textarea>
												{errorMessages["details"] && (<div className="text-danger pt-2">{errorMessages["details"][0]}</div>)}
												<p style={{ color: "red" }}>Please don't share any contact details (phone, email, website etc) here</p>
												<br />
											</div>
											<input type="button" name="previous" className="previous action-button-previous btn btn-outline-secondary Post_requirement" defaultValue="Previous" />
											<input type="button" name="next" className="next action-button btn btn-primary ms-4 Post_requirement" defaultValue="Next Step" />
										</fieldset>

										<fieldset className="Subjects_you_Teach  mx-auto">
											<div>
												<h3>
													<a href="#">Add a subject</a>
												</h3>
												<div className="mt-4">
													<div className="mb-3">
														<label htmlFor="exampleFormControlInput1" className="form-label m-0">
															Subject (one at a time)
														</label>
														<select className="form-control" id="exampleFormControlInput1" name="subject_ids" multiple="multiple" data-tags="true" onChange={handlePostInfoChange}>
															{subjects.map((subject, index) => (
																<option key={index} value={subject.id}>
																	{subject.title}
																</option>
															))}
														</select>
														<a href="#"> If not in options above, add a new subject.</a>
														{errorMessages["subject_ids"] && (<div className="text-danger pt-2">{errorMessages["subject_ids"][0]}</div>)}
													</div>
													<label htmlFor="level" className="form-label mt-2">
														Level
													</label>
													<select id="level" className="form-select" aria-label="Default select example" name="level_id" onChange={handlePostInfoChange}>
														<option className="title">-- Select Level --</option>
														{
															options.level.map((help, index) => (
																<optgroup key={index} label={help.option}>
																	{
																		help.sub_options.map((sublevel, ind) => (
																			<option key={ind} value={sublevel.id}>{sublevel.option}</option>
																		))
																	}
																</optgroup>
															))
														}
													</select>
													{errorMessages["level_id"] && (<div className="text-danger pt-2">{errorMessages["level_id"][0]}</div>)}

													<label htmlFor="help_type" className="form-label mt-4">
														I want
													</label>
													<select className="form-select" id="help_type" name="help_type_id" aria-label="Default select example" onChange={handlePostInfoChange}>
														<option className="title">
															-- Select --
														</option>
														{
															options.type_of_help.map((help, index) => (
																<option key={index} value={help.id}>{help.option}</option>
															))
														}
													</select>
													{errorMessages["help_type_id"] && (<div className="text-danger pt-2">{errorMessages["help_type_id"][0]}</div>)}

													<label name="meeting_options" htmlFor="validationCustom02" className="form-label mt-4">
														Meeting options
													</label>
													{
														options.meeting_options.map((meetOpt, index) => (
															<p key={index}>
																<input type="radio" name="meeting_option_id" value={meetOpt.id} id={meetOpt.option} onChange={handlePostInfoChange} />
																<label htmlFor={meetOpt.option}>{meetOpt.option}</label>
															</p>
														))
													}

													{errorMessages["meeting_options"] && (<div className="text-danger pt-2">{errorMessages["meeting_options"][0]}</div>)}

													{" "}
													<br />
													<label htmlFor="due_date">Due Date</label>
													<input className="form-control" type="date" name="due_date" id="due_date" onChange={handlePostInfoChange} />
													{errorMessages["due_date"] && (<div className="text-danger pt-2">{errorMessages["due_date"][0]}</div>)}
													<br />
													{travelToTutor && (
														<div>
															<label htmlFor="travel">How much can you travel to tutor?</label>
															<input className="form-control" type="text" id="travel" />
														</div>
													)}
													<br></br>{" "}
												</div>
											</div>
											<input type="button" name="previous" className="previous action-button-previous btn btn-outline-secondary Post_requirement" defaultValue="Previous" />
											<input type="button" name="make_payment" className="next action-button btn btn-primary ms-4 Post_requirement" defaultValue="Confirm" />
										</fieldset>
										<fieldset className="Subjects_you_Teach  mx-auto">
											<div className="form-card">
												<div className="row g-3">
													<div className="col-md-6">
														<label htmlFor="budget">Budget</label>
														<input type="text" name="budget" className="form-control" id="budget" onChange={handlePostInfoChange} />
														{errorMessages["budget"] && (<div className="text-danger pt-2">{errorMessages["budget"][0]}</div>)}
													</div>
													<div className="col-md-6">
														<label htmlFor="budget_type">.</label>
														<select className="form-select" name="budget_type_id" id="budget_type" aria-label="Default select example" onChange={handlePostInfoChange}>
															<option className="title">Budget Type</option>
															{
																options.budget.map((budgetType, index) => (
																	<option key={index} value={budgetType.id}>{budgetType.option}</option>
																))
															}
														</select>{" "}
														{errorMessages["budget_type_id"] && (<div className="text-danger pt-2">{errorMessages["budget_type_id"][0]}</div>)}
													</div>
												</div>
												<br />
												<label htmlFor="gender_preference_id">Gender Preference:</label>
												<select name="gender_preference_id" className="text-capitalize form-select" id="gender_preference_id" required onChange={handlePostInfoChange}>
													{
														options.gender_preference.map((genderPref, index) => (
															<option key={index} value={genderPref.id}>{genderPref.option}</option>
														))
													}
												</select>
												{errorMessages["gender_preference_id"] && (<div className="text-danger pt-2">{errorMessages["gender_preference_id"][0]}</div>)}
												<br />
												<label htmlFor="communicate">I can communicate with the tutor in (languages)</label>
												<input className="form-control" type="text" id="communicate" name="communicate" onChange={handlePostInfoChange} />
												{errorMessages["communicate"] && (<div className="text-danger pt-2">{errorMessages["communicate"][0]}</div>)}

												<br />
												<label>Upload files</label>
												<input className="form-control" type="File" />
												<br />
											</div>
											<input type="button" name="previous" className="Post_requirement previous action-button-previous btn btn-outline-secondary " defaultValue="Previous" />
											{/* <NavLink to="/Verifytutor"> */}
											{" "}
											<input type="button" className="Post_requirement action-button btn btn-primary ms-md-4 mt-3 mt-xl-0 mt-lg-0 mt-md-0 " value="Post requirement and get teachers" onClick={(e) => handleRequestTutor(e, 'postSubmit')} />
											{/* </NavLink> */}
										</fieldset>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default RequestTutor;
