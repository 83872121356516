// TeacherSettings.jsx
import React from "react";
import { NavLink } from "react-router-dom";

const TeacherSettings = () => {
  return (
    <>
      <h3 className="my-4">Tutor account settings</h3>
      <div className="d-flex gap-3 mb-4">
        <div className="mt-2">
          <i className="fa fa-light fa-address-book" style={{ color: "#adcd4b" }}></i>
        </div>
        <div className="Profile_content d-flex flex flex-column gap-2">
          <h6 className="m-0">Manage Show Contact Settings</h6>
          <p>Options to set how your contact details are shown to students.</p>
          <NavLink to="/showContactDetail"> Show Contact Settings</NavLink>
        </div>
      </div>

      <div className="d-flex gap-3 mb-4">
        <div className="mt-2">
          <i className="fa-solid fa-credit-card" style={{ color: "#adcd4b" }}></i>
        </div>
        <div className="Profile_content d-flex flex flex-column gap-2">
          <h6 className="m-0">Payment receiving settings</h6>
          <p>Manage accounts in which you receive payment when a student pays you.</p>
          <a href="#">Accounts for getting paid</a>
        </div>
      </div>

      <div className="d-flex gap-3 mb-4">
        <div className="mt-2">
          <i className="fa-solid fa-magnifying-glass" style={{ color: "#adcd4b" }}></i>
        </div>
        <div className="Profile_content d-flex flex flex-column gap-2">
          <h6 className="m-0">Search Engine visibility</h6>
          <p>Your profile can be seen by Google and other search engines (recommended)</p>
          <a href="#">Hide profile from Google (not recommended)</a>
        </div>
      </div>

      <div className="d-flex gap-3 mb-4">
        <div className="mt-2">
          <i className="fa-solid fa-user" style={{ color: "#adcd4b" }}></i>
        </div>
        <div className="Profile_content d-flex flex flex-column gap-2">
          <h6 className="m-0">Profile Active</h6>
          <p>Deactivate your profile if you wish to take a temporary break from teaching.</p>
          <p>
            <i className="fa-solid fa-triangle-exclamation pe-2" style={{ color: "#f8993f" }}></i> A If you deactivate profile students will not be able to contact you until you activate it again.
          </p>
          <a href="#">Deactivate profile</a>
        </div>
      </div>
      <div className="d-flex gap-3 mb-4">
        <div className="mt-2">
          <i className="fa-solid fa-suitcase" style={{ color: "#adcd4b" }}></i>
        </div>
        <div className="Profile_content d-flex flex flex-column gap-2">
          <h6 className="m-0">Email preferences</h6>
          <p>
            <input type="checkbox" />
            Get Job notifications
          </p>
          <p>
            <input type="checkbox" /> Get notification of local students around me
          </p>
        </div>
      </div>
    </>
  );
};

export default TeacherSettings;
