import React from "react";

const Reviews = () => {
  return (
    <>
      <div className="cointainer mt-5 Small_containers">
        <div className="row">
          <div className="Subjects_you_Teach mx-auto">
            <h3>Manage reviews</h3>

            <div class="alert alert-danger mb-0 mt-4" role="alert">
              you have not given any reviews yet
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reviews;
