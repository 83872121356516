import React, { useState } from "react";
import { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
// import "../../sim.css";
import $ from "jquery";
import axios from "../../settings/axiosConfig.jsx";
import { getOptions } from "../../settings/options.jsx";
import { useNavigate } from 'react-router-dom';




const TeachingDetails = () => {


	const navigate = useNavigate();


	const accessToken = localStorage.getItem('accessToken');
	// Set the Authorization header with the token
	axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

	// For fetching location
	const [query, setQuery] = useState("");
	const [searchResults, setSearchResults] = useState([]);

	const [subjects, setSubjects] = useState([]);

	// GET SUBJECTS STARTS
	useEffect(() => {
		axios.get('/subjects')
			.then((response) => {
				if (response.data.success) {
					setSubjects(response.data.data);
				}
			})
			.catch((error) => {
			});
	}, []);
	// GET SUBJECTS ENDS


	const handleSearch = async () => {
		try {
			const apiKey = "d95a1ee8e3334891b29100237231110";
			const apiUrl = `https://api.weatherapi.com/v1/search.json?key=${apiKey}&q=${query}`;

			console.log("API URL:", apiUrl);

			const response = await fetch(apiUrl);
			console.log("API Response:", response);

			const data = await response.json();
			console.log("API Data:", data);

			setTimeout(() => {
				let country = data.country
			}, 3000)

			// Check if 'locations' array exists in the response
			const locations = data || [];

			if (locations.length > 0) {
				setSearchResults(locations);
			} else {
				console.warn("No locations found in the API response.");
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};


	const handleLocationInputChange = (event) => {
		const newQuery = event.target.value;
		setQuery(newQuery);

		// Perform search on each input change
		handleSearch();
	};


	// ========== GET OPTIONS STARTS ========== 
	const [options, setOptions] = useState({
		gender: [],
		budget: [],
		fee_type: [],
		employment_status: [],
		level: [],
		degree_type: [],
		tutor_educational_association: [],
		tutor_experience_association: []
	});

	useEffect(() => {
		const optionNames = ["gender", "degree_type", "tutor_educational_association", "tutor_experience_association", "budget", "fee_type", "employment_status", "level"];

		getOptions(optionNames)
			.then(result => {
				setOptions(result);
			})
			.catch(error => {
				// Handle the error
			});
	}, []);

	console.log(options);

	// ========== GET OPTIONS ENDS ========== 


	// Profile Information
	const [basicFormData, setBasicFormData] = useState({
		strength: "",
		gender: "",
		date_of_birth: ""
	});

	const [basicErrorMessages, setBasicErrorMessages] = useState(false);
	const [basicSuccessMessage, setBasicSuccessMessage] = useState(false);

	const handleBasicInfoChange = (e) => {
		setBasicFormData({ ...basicFormData, [e.target.name]: e.target.value });
	};


	// Location Information
	const [locationFormData, setLocationFormData] = useState({
		country_id: "",
		city_name: "",
		state_name: "",
	});

	const [tutorSubjectData, setTutorSubjectData] = useState({
		from_level_id: "",
		to_level_id: "",
		subject_id: ""
	});

	const [locationErrorMessages, setLocationErrorMessages] = useState(false);
	const [locationSuccessMessage, setLocationSuccessMessage] = useState(false);

	const handleLocationChange = (e) => {
		setLocationFormData({ ...locationFormData, [e.target.name]: e.target.value });
	};

	const handleSubjectChange = (e) => {
		setTutorSubjectData({ ...tutorSubjectData, [e.target.name]: e.target.value });
	};


	// Charges Information
	const [chargesFormData, setChargesFormData] = useState({
		fee_type_id: "",
		fee_currency: "",
		min_charge_in_tutor_currency: "",
		max_charge_in_tutor_currency: "",
		fee_vary_detail: "",
		years_of_experience: "",
		have_digital_pen: "",
		help_with_hw_assignment: "",
		currently_full_time_status: "",
		looking_for_employment_status: "",
	});

	const [chargesErrorMessages, setChargesErrorMessages] = useState(false);
	const [chargesSuccessMessage, setChargesSuccessMessage] = useState(false);

	const handleChargesChange = (e) => {
		setChargesFormData({ ...chargesFormData, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		axios.get('/users/profile')
			.then((response) => {
				const tutorInfo = response.data.data;

				if (tutorInfo.tutor_information) {
					setBasicFormData({
						strength: tutorInfo.tutor_information.strength,
						gender: tutorInfo.gender,
						date_of_birth: tutorInfo.date_of_birth
					})

				}
				if (tutorInfo.tutor_location) {
					setLocationFormData(tutorInfo.tutor_location);
				}

				if (tutorInfo.charges) {
					setChargesFormData(tutorInfo.charges);
				}

				if (tutorInfo.tutor_subjects) {
					setTutorSubjectData(tutorInfo.tutor_subjects);
				}

				// setSubjectData(tutorInfo.tutor_subjects);
			})
			.catch((error) => {
				console.error('Error fetching user profile:', error);
			});
	}, []);

	const handleTutorProfile = async (e, formSection) => {
		e.preventDefault();
		try {

			switch (formSection) {
				case 'basicInfo':
					const metaResponse = await axios.post(`tutor-meta`, basicFormData);
					const profileResponse = await axios.put(`users/profile`, basicFormData);
					console.log(profileResponse);
					break;

				case 'subjects':
					const subjectResponse = await axios.post(`tutor-subject`, tutorSubjectData);
					console.log(subjectResponse)
					break;
				case 'charges':
					const chargesResponse = await axios.post(`tutor-charges`, chargesFormData);
					break;
				default:
					break;
			}
            navigate('/SFP/pro_des');
		} catch (error) {
		}
	};


	useEffect(() => {
		$(document).ready(function () {
			var current_fs, next_fs, previous_fs; //fieldsets
			var opacity;

			$(".next").click(function () {
				current_fs = $(this).parent();
				next_fs = $(this).parent().next();

				//Add Class Active
				$("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

				//show the next fieldset
				next_fs.show();
				//hide the current fieldset with style
				current_fs.animate(
					{ opacity: 0 },
					{
						step: function (now) {
							// for making fielset appear animation
							opacity = 1 - now;

							current_fs.css({
								display: "none",
								position: "relative",
							});
							next_fs.css({ opacity: opacity });
						},
						duration: 600,
					}
				);
			});

			$(".previous").click(function () {
				current_fs = $(this).parent();
				previous_fs = $(this).parent().prev();

				//Remove class active
				$("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

				//show the previous fieldset
				previous_fs.show();

				//hide the current fieldset with style
				current_fs.animate(
					{ opacity: 0 },
					{
						step: function (now) {
							// for making fielset appear animation
							opacity = 1 - now;

							current_fs.css({
								display: "none",
								position: "relative",
							});
							previous_fs.css({ opacity: opacity });
						},
						duration: 600,
					}
				);
			});

			$(".radio-group .radio").click(function () {
				$(this).parent().find(".radio").removeClass("selected");
				$(this).addClass("selected");
			});

			$(".submit").click(function () {
				return false;
			});
		});
	}, []);
    return (
        <div>
        <div id="msform">
                                            {/* progressbar */}
                                            <ul id="progressbar">
                                                <li className="active" id="account">
                                                    <strong>Your basic info</strong>
                                                </li>
                                                <li className="active" id="personal">
                                                    <strong>Address </strong>
                                                </li>
                                                <li className="active" id="payment">
                                                    <strong>Subjects you Teach</strong>
                                                </li>
                                                <li className="active"  id="confirm">
                                                    <strong>Education</strong>
                                                </li>
                                                <li className="active" id="confirm1">
                                                    <strong>Professional</strong>
                                                </li>
                                                <li className="active" id="TeachingDetails">
                                                    <strong>Teaching Details</strong>
                                                </li>
                                                <li id="ProfileDescription">
                                                    <strong>Profile Description</strong>
                                                </li>
                                            </ul>
                                            
    
                                        </div>
      
                        <div className="Subjects_you_Teach mx-auto">
                            <div className="form-card Teaching_Details_main">
                                <h5>Fee in CAD </h5>
                                <div className="row g-3 needs-validation row-gap-3">
                                    <div className="col-md-4 mb-4">
                                        <label htmlFor="charge_type" className="form-label">
                                            I charge
                                        </label>
                                        <select className="form-select" id="charge_type" name="fee_type_id" onChange={handleChargesChange} value={chargesFormData.fee_type_id}>
                                            <option>Select</option>
                                            {
                                                options.fee_type.map((fee, index) => (
                                                    <option key={index} value={fee.id}>{fee.option}</option>
                                                ))
                                            }
                                        </select>

                                        <input type="text" name="fee_currency" id="fee_currency" className="form-control" onChange={handleChargesChange} value={chargesFormData.fee_currency} />
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <label htmlFor="min_charge_in_tutor_currency" className="form-label">
                                            Minimum fee
                                        </label>

                                        <input type="number" className="form-control" id="min_charge_in_tutor_currency" name="min_charge_in_tutor_currency" required onChange={handleChargesChange} value={chargesFormData.min_charge_in_tutor_currency} />
                                        <div className="valid-feedback">Looks good!</div>
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <label htmlFor="max_charge_in_tutor_currency" className="form-label">
                                            Maximum fee
                                        </label>
                                        <input type="number" className="form-control" id="max_charge_in_tutor_currency" name="max_charge_in_tutor_currency" required onChange={handleChargesChange} value={chargesFormData.max_charge_in_tutor_currency} />
                                        <div className="valid-feedback">Looks good!</div>
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="fee_vary_detail">Fee Details (Details of how fee can vary)</label>
                                        <textarea className="form-control mx-2 mt-0" id="fee_vary_detail" rows="6" name="fee_vary_detail" onChange={handleChargesChange} value={chargesFormData.fee_vary_detail}></textarea>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="years_of_experience" className="form-label">
                                            Years of total experience (Teaching and other experience)
                                        </label>
                                        <input type="number" className="form-control" id="years_of_experience" name="years_of_experience" onChange={handleChargesChange} value={chargesFormData.years_of_experience} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="years_of_online_offline_experience" className="form-label">
                                            Years of total teaching experience (Offline+online){" "}
                                        </label>
                                        <input type="number" className="form-control" id="years_of_online_offline_experience" name="years_of_online_offline_experience" onChange={handleChargesChange} value={chargesFormData.years_of_online_offline_experience} />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="years_of_online_experience" className="form-label">
                                            Years of online teaching experience
                                        </label>
                                        <input type="number" className="form-control" name="years_of_online_experience" id="years_of_online_experience" onChange={handleChargesChange} value={chargesFormData.years_of_online_experience} />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Opportunities you are interested in</label>
                                        <select className="form-select" aria-label="Default select example" name="looking_for_employment_status" onChange={handleChargesChange} value={chargesFormData.looking_for_employment_status}>
                                            <option>Please select</option>
                                            {options.employment_status.map((opt, index) => (
                                                <option key={index} value={opt.id}>
                                                    {opt.option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                            {" "}
                                            Are you willing to travel to Student?{" "}
                                        </label>

                                        <div className="d-flex mt-0">
                                            <div className='form-check form-check-inline'>
                                                <input type="radio" id="yes_travel" name="willing_to_travel" onChange={handleChargesChange} className='form-check-input' value={1} checked={chargesFormData.willing_to_travel === '1'} />
                                                <label htmlFor='yes_travel' className='form-check-label'>Yes</label>
                                            </div>

                                            <div className='form-check form-check-inline'>
                                                <input type="radio" id="no_travel" name="willing_to_travel" onChange={handleChargesChange} className='form-check-input' value={0} checked={chargesFormData.willing_to_travel === '0'} />
                                                <label htmlFor='no_travel' className='form-check-label'>No</label>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="mb-3">
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                            {" "}
                                            Available for online teaching?
                                        </label>

                                        <div className="d-flex mt-0">
                                            <div className='form-check form-check-inline'>
                                                <input type="radio" id="yes_online" name="available_for_online_teaching" onChange={handleChargesChange} className='form-check-input' value={1} checked={chargesFormData.available_for_online_teaching === '1'} />
                                                <label htmlFor='yes_online' className='form-check-label'>Yes</label>
                                            </div>

                                            <div className='form-check form-check-inline'>
                                                <input type="radio" id="no_online" name="available_for_online_teaching" onChange={handleChargesChange} className='form-check-input' value={0} checked={chargesFormData.available_for_online_teaching === '0'} />
                                                <label htmlFor='no_online' className='form-check-label'>No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                            {" "}
                                            Do you have a digital Pen?
                                        </label>

                                        <div className="d-flex mt-0">
                                            <div className='form-check form-check-inline'>
                                                <input type="radio" id="yes_digital_pen" name="have_digital_pen" onChange={handleChargesChange} className='form-check-input' value={1} checked={chargesFormData.have_digital_pen === '1'} />
                                                <label htmlFor='yes_digital_pen' className='form-check-label'>Yes</label>
                                            </div>

                                            <div className='form-check form-check-inline'>
                                                <input type="radio" id="no_digital_pen" name="have_digital_pen" onChange={handleChargesChange} className='form-check-input' value={0} checked={chargesFormData.have_digital_pen === '0'} />
                                                <label htmlFor='no_digital_pen' className='form-check-label'>No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mb-3'>
                                        <label>Will you help with Homewrok/ Assignment?</label>

                                        <div className="d-flex mt-0">

                                            <div className='form-check form-check-inline'>
                                                <input type="radio" id="yes_hw" name="help_with_hw_assignment" onChange={handleChargesChange} className='form-check-input' value={1} checked={chargesFormData.help_with_hw_assignment === '1'} />
                                                <label htmlFor='yes_hw' className='form-check-label'>Yes</label>
                                            </div>

                                            <div className='form-check form-check-inline'>
                                                <input type="radio" id="no_hw" name="help_with_hw_assignment" onChange={handleChargesChange} className='form-check-input' value={0} checked={chargesFormData.help_with_hw_assignment === '0'} />
                                                <label htmlFor='no_hw' className='form-check-label'>No</label>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='mb-3'>
                                        <label>Are you working as full Time currently?</label>

                                        <div className="d-flex mt-0">
                                            <div className='form-check form-check-inline'>
                                                <input type="radio" id="yes_full_time" name="currently_full_time_status" onChange={handleChargesChange} className='form-check-input' value={1} checked={chargesFormData.currently_full_time_status === '1'} />
                                                <label htmlFor='yes_full_time' className='form-check-label'>Yes</label>
                                            </div>

                                            <div className='form-check form-check-inline'>
                                                <input type="radio" id="no_full_time" name="currently_full_time_status" onChange={handleChargesChange} className='form-check-input' value={0} checked={chargesFormData.currently_full_time_status === '0'} />
                                                <label htmlFor='no_full_time' className='form-check-label'>No</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <Link to="/SFP/professional">
                            <input type="button" name="previous" className="previous action-button-previous btn btn-outline-secondary" defaultValue="Previous" />
                            </Link>
                            {/* <Link to="/SFP/pro_des"> */}
                            <input type="button"  className=" btn btn-primary ms-4" defaultValue="Submit" onClick={(e) => handleTutorProfile(e, 'charges')} />
                            {/* </Link> */}
                        </div>
                        </div>

                  
    )
}

export default TeachingDetails