import React from 'react'

const ChangeName = () => {
  return (
    <>
      <section className="py-5">
        <div className="container">
          <div className="change_Numbers_page">
            <h3>Change name</h3>
            <label htmlFor="exampleInputEmail1" className="form-label mt-5">Full name</label>
            <div className="input-group">
              <input type="email" className="form-control Old_email" id="exampleInputEmail1" aria-describedby="emailHelp" value="Marcia Rhodes " />
            </div>
            <label htmlFor="exampleInputEmail1" className="form-label mt-3">ID Proof</label>
            <div className="input-group">
              <input type="file" className="form-control Old_email" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
            <button>Save</button>
          </div>
        </div>
      </section>
    </>
  )
}

export default ChangeName