import React from "react";

const CourseTeach = () => {
  return (
    <>
      <div className="cointainer mt-5 Small_containers CourseTeach_page">
        <div className="row">
          <div className="Subjects_you_Teach mx-auto">
            <div className="Subjects_you_Teach mx-auto">
              <div className="form-card">
                <h3>Courses I teach</h3>
                <div className="mb-4">
                  <label htmlFor="institute">Course title</label>

                  <input className="form-control" type="institute" name="institute" id="institute" />
                </div>

                <div className="mb-4">
                  <label htmlFor="designation">Description</label>
                  <textarea className="form-control" rows="6" name="description" id="description"></textarea>
                </div>

                <div className="mb-4">
                  <label htmlFor="description"> Price</label>
                  <div class="input-group ">
                    <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Select
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                      <li>
                        <hr class="dropdown-divider" />
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Separated link
                        </a>
                      </li>
                    </ul>
                    <input type="text" class="form-control" aria-label="Text input with dropdown button" />
                  </div>
                  <p>Setting price 0 will make this course free.</p>
                </div>
                {/* ============first select=============== */}
                <label htmlFor="description"> Mode of delivery</label>
                <select class="form-select mb-3" aria-label="Default select example">
                  <option selected>Please select</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>

                {/* ============second select=============== */}
                <label htmlFor="description">Group size</label>
                <select class="form-select mb-3" aria-label="Default select example">
                  <option selected>Please select</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>

                {/* ============third select=============== */}
                <label htmlFor="description"> Certificate provided?</label>
                <select class="form-select mb-3" aria-label="Default select example">
                  <option selected>Please select</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                <div className="mb-4">
                  <label htmlFor="institute">Language of instructions</label>
                  <input className="form-control" type="institute" name="institute" id="institute" />
                </div>
                <div className="mb-4">
                  <h4>Course duration</h4>
                  <label htmlFor="institute">e.g. 10 days, 20 hours etc.</label>
                  <input className="form-control" type="institute" name="institute" id="institute" />
                </div>
              </div>

              <input type="button" name="next" className="next action-button btn btn-primary px-4 check_btn" defaultValue="Save" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseTeach;
