import React from "react";
import { NavLink } from "react-router-dom";

const ChosePaymentMethod = () => {
  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-8 ma border ChosePaymentMethod-cls m">
            <h3 className="mt-5 ">Choose A payment Method</h3>
            <div className="d-flex ma mb-5 text-center " style={{ width: "fit-content" }}>
              <i className="fa-solid fa-coins sikka me-2 txt-clr "></i>
              <p className=""> 50 coins (You Save 0%) </p>
            </div>
            <div className="pay-box border  ma">
              <p className="">83.28</p>
              <p className="">No transaction fee (recommended)</p>
              <button type="btn" className="text-white btn-primary bg-clr-green w-75 mt-4">
                {" "}
                Card Netbanking UPI Wallet
              </button>
            </div>

            <div className=" d-fb w-100 ma mt-5">
              <div className="pay-box border  ma">
                <p className=" ma">1 USD</p>
                <p className=" ma">No transaction fee</p>
                <button type="btn" className="text-white btn-primary bg-clr-green w-75 mt-4 ma">
                  {" "}
                  Card
                </button>
              </div>

              <div className="pay-box border ma">
                <p className=" ma">1.34 USD</p>
                <p className=" ma">10.34 transaction fee</p>
                <button type="btn" className="text-white btn-primary bg-clr-green w-75 mt-4 ma">
                  {" "}
                  $ Card
                </button>
              </div>
            </div>

            <div className="pay-box border  ma mt-5 mb-5">
              <p className=""> 86.53 INR</p>
              <p className="">83.28 3.25 transaction fee</p>
              <button type="btn" className=" text-white btn-primary bg-clr-green w-75 mt-4">
                <i className="fa-solid fa-coins  me-2 text-white "></i> $ Card
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChosePaymentMethod;
