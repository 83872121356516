import React from 'react'
import { NavLink } from 'react-router-dom'
// import { NavLink } from 'react-router-dom'

const NewPassward = () => {
  return (
    <>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-5 ma border NewPassward-cls ' style={{ padding: "30px 20px" }}>
            <h5 className='mb-5'>New Passward</h5>
            <hr />
            <div className="mb-3 text-start mt-5">
              <label htmlFor="exampleInputEmail1" className="form-label ">Passward</label>
              <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
            <button type="buttton" className='btn border w-100 mt-4 bg-clr-green'>Reset Passward</button>

            <NavLink to="/Login"><div id="emailHelp" className="form-text text-start mt-4 hover-underline">Click here to go to login screen</div></NavLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewPassward