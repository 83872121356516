import React from "react";

const ShowContactDetail = () => {
  return (
    <>
      <div className="container">
        <div className="row mt-5">
          <div className="Subjects_you_Teach mx-auto border text-center p-4 rounded-3">
            <h3 className="">Show Contact detail settings</h3>
            <div className="">
              <p>What is free contact sharing?</p>
              <p> What is paid contact sharing?</p>
              <p>How much does paid contact sharing cost?</p>
              <p>What if the student query is fake/not genuine/or I never get a call?</p>
              <p>Is it safe to share contact details?</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowContactDetail;
