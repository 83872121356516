import React, { useState } from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import axios from "../../settings/axiosConfig.jsx";
import { Link } from "react-router-dom";
import { getOptions } from "../../settings/options.jsx";
import { useNavigate } from 'react-router-dom';

const Professional = () => {


	const accessToken = localStorage.getItem('accessToken');
	// Set the Authorization header with the token
	axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

	// For fetching location
	const [query, setQuery] = useState("");
	const [searchResults, setSearchResults] = useState([]);

	const [subjects, setSubjects] = useState([]);

	// GET SUBJECTS STARTS
	useEffect(() => {
		axios.get('/subjects')
			.then((response) => {
				if (response.data.success) {
					setSubjects(response.data.data);
				}
			})
			.catch((error) => {
			});
	}, []);
	// GET SUBJECTS ENDS


	const handleSearch = async () => {
		try {
			const apiKey = "d95a1ee8e3334891b29100237231110";
			const apiUrl = `https://api.weatherapi.com/v1/search.json?key=${apiKey}&q=${query}`;

			console.log("API URL:", apiUrl);

			const response = await fetch(apiUrl);
			console.log("API Response:", response);

			const data = await response.json();
			console.log("API Data:", data);

			setTimeout(() => {
				let country = data.country
			}, 3000)

			// Check if 'locations' array exists in the response
			const locations = data || [];

			if (locations.length > 0) {
				setSearchResults(locations);
			} else {
				console.warn("No locations found in the API response.");
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};


	const handleLocationInputChange = (event) => {
		const newQuery = event.target.value;
		setQuery(newQuery);

		// Perform search on each input change
		handleSearch();
	};


	// ========== GET OPTIONS STARTS ========== 
	const [options, setOptions] = useState({
		gender: [],
		budget: [],
		fee_type: [],
		employment_status: [],
		level: [],
		degree_type: [],
		tutor_educational_association: [],
		tutor_experience_association: []
	});

	useEffect(() => {
		const optionNames = ["gender", "degree_type", "tutor_educational_association", "tutor_experience_association", "budget", "fee_type", "employment_status", "level"];

		getOptions(optionNames)
			.then(result => {
				setOptions(result);
			})
			.catch(error => {
				// Handle the error
			});
	}, []);

	console.log(options);

	// ========== GET OPTIONS ENDS ========== 


	// Profile Information
	const [basicFormData, setBasicFormData] = useState({
		strength: "",
		gender: "",
		date_of_birth: ""
	});

	const [basicErrorMessages, setBasicErrorMessages] = useState(false);
	const [basicSuccessMessage, setBasicSuccessMessage] = useState(false);

	const handleBasicInfoChange = (e) => {
		setBasicFormData({ ...basicFormData, [e.target.name]: e.target.value });
	};


	// Location Information
	const [locationFormData, setLocationFormData] = useState({
		country_id: "",
		city_name: "",
		state_name: "",
	});

	const [tutorSubjectData, setTutorSubjectData] = useState({
		from_level_id: "",
		to_level_id: "",
		subject_id: ""
	});

	const [locationErrorMessages, setLocationErrorMessages] = useState(false);
	const [locationSuccessMessage, setLocationSuccessMessage] = useState(false);

	const handleLocationChange = (e) => {
		setLocationFormData({ ...locationFormData, [e.target.name]: e.target.value });
	};

	const handleSubjectChange = (e) => {
		setTutorSubjectData({ ...tutorSubjectData, [e.target.name]: e.target.value });
	};


	// Charges Information
	const [chargesFormData, setChargesFormData] = useState({
		fee_type_id: "",
		fee_currency: "",
		min_charge_in_tutor_currency: "",
		max_charge_in_tutor_currency: "",
		fee_vary_detail: "",
		years_of_experience: "",
		have_digital_pen: "",
		help_with_hw_assignment: "",
		currently_full_time_status: "",
		looking_for_employment_status: "",
	});

	const [chargesErrorMessages, setChargesErrorMessages] = useState(false);
	const [chargesSuccessMessage, setChargesSuccessMessage] = useState(false);

	const handleChargesChange = (e) => {
		setChargesFormData({ ...chargesFormData, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		axios.get('/users/profile')
			.then((response) => {
				const tutorInfo = response.data.data;

				if (tutorInfo.tutor_information) {
					setBasicFormData({
						strength: tutorInfo.tutor_information.strength,
						gender: tutorInfo.gender,
						date_of_birth: tutorInfo.date_of_birth
					})

				}
				if (tutorInfo.tutor_location) {
					setLocationFormData(tutorInfo.tutor_location);
				}

				if (tutorInfo.charges) {
					setChargesFormData(tutorInfo.charges);
				}

				if (tutorInfo.tutor_subjects) {
					setTutorSubjectData(tutorInfo.tutor_subjects);
				}

				// setSubjectData(tutorInfo.tutor_subjects);
			})
			.catch((error) => {
				console.error('Error fetching user profile:', error);
			});
	}, []);

	const handleTutorProfile = async (e, formSection) => {
		e.preventDefault();
		try {

			switch (formSection) {
				case 'basicInfo':
					const metaResponse = await axios.post(`tutor-meta`, basicFormData);
					const profileResponse = await axios.put(`users/profile`, basicFormData);
					console.log(profileResponse);
					break;

				case 'subjects':
					const subjectResponse = await axios.post(`tutor-subject`, tutorSubjectData);
					console.log(subjectResponse)
					break;
				case 'charges':
					const chargesResponse = await axios.post(`tutor-charges`, chargesFormData);
					break;
				default:
					break;
			}

		} catch (error) {
		}
	};


	useEffect(() => {
		$(document).ready(function () {
			var current_fs, next_fs, previous_fs; //fieldsets
			var opacity;

			$(".next").click(function () {
				current_fs = $(this).parent();
				next_fs = $(this).parent().next();

				//Add Class Active
				$("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

				//show the next fieldset
				next_fs.show();
				//hide the current fieldset with style
				current_fs.animate(
					{ opacity: 0 },
					{
						step: function (now) {
							// for making fielset appear animation
							opacity = 1 - now;

							current_fs.css({
								display: "none",
								position: "relative",
							});
							next_fs.css({ opacity: opacity });
						},
						duration: 600,
					}
				);
			});

			$(".previous").click(function () {
				current_fs = $(this).parent();
				previous_fs = $(this).parent().prev();

				//Remove class active
				$("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

				//show the previous fieldset
				previous_fs.show();

				//hide the current fieldset with style
				current_fs.animate(
					{ opacity: 0 },
					{
						step: function (now) {
							// for making fielset appear animation
							opacity = 1 - now;

							current_fs.css({
								display: "none",
								position: "relative",
							});
							previous_fs.css({ opacity: opacity });
						},
						duration: 600,
					}
				);
			});

			$(".radio-group .radio").click(function () {
				$(this).parent().find(".radio").removeClass("selected");
				$(this).addClass("selected");
			});

			$(".submit").click(function () {
				return false;
			});
		});
	}, []);


	return (
		<>
		 <div>
	<div id="msform">
										{/* progressbar */}
										<ul id="progressbar">
											<li className="active" id="account">
												<strong>Your basic info</strong>
											</li>
											<li className="active" id="personal">
												<strong>Address </strong>
											</li>
											<li className="active" id="payment">
												<strong>Subjects you Teach</strong>
											</li>
											<li className="active"  id="confirm">
												<strong>Education</strong>
											</li>
											<li className="active" id="confirm1">
												<strong>Professional</strong>
											</li>
											<li id="TeachingDetails">
												<strong>Teaching Details</strong>
											</li>
											<li id="ProfileDescription">
												<strong>Profile Description</strong>
											</li>
										</ul>
										

									</div>
			<fieldset className="Subjects_you_Teach mx-auto">
				<div className="form-card">

					<div className="mb-4">
						<label htmlFor="institute">Organization name with city</label>
						<input className="form-control" type="institute" name="institute" id="institute" />
					</div>

					<div className="mb-4">
						<label htmlFor="designation">Designation</label>
						<input className="form-control" type="text" name="designation" id="designation" />
					</div>

					<div className="row g-3">
						<div className="col-md-6 mb-4">
							<label htmlFor="exp_start_month">Start Date</label>
							<input type="month" className="form-control" id="exp_start_month" name="exp_start_month" />
						</div>
						<div className="col-md-6 mb-4">
							<label htmlFor="exp_end_month">End Date</label>
							<input type="month" className="form-control" id="exp_end_month" name="exp_end_month" />
						</div>
					</div>

					<div className="mb-4">
						<label htmlFor="exp_association_id">Association</label>
						<select className="form-select" aria-label="Default select example" name="exp_association_id" id="exp_association_id">
							<option>Please select </option>
							{
								options.tutor_experience_association.map((exp) => (
									<option value={exp.id}>{exp.option}</option>
								))
							}
						</select>
					</div>

					<div className="mb-4">
						<label htmlFor="description">Job Description</label>
						<textarea className="form-control" rows="6" name="description" id="description"></textarea>
					</div>
				</div>
				<Link to="/SFP/education">
				<input type="button" name="previous" className="previous action-button-previous btn btn-outline-secondary" defaultValue="Previous" />
				</Link>
				<Link to="/SFP/teach_detail">
					<input type="button" name="next" className="next action-button btn btn-primary ms-4" defaultValue="Submit" />
				</Link>
			</fieldset>

			</div>
		</>
	)
}

export default Professional;