// import Header from './components/Header/Header';
import Footer from "./components/Footer/Footer";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import Header from "./components/Header/Header";
import { Route, Routes, Navigate } from "react-router-dom";
import SinglePage from "./SinglePage";
import TeacherProfileDashboard from "./components/Teacher Form/Teacher-profile-dashboard";
import VerifyEmail from "./components/Login/VerifyEmail";
import LoginPage from "./components/Login/LoginPage";
import TeachersForm from "./components/Teacher Form/TeachersForm";
import TeacherFormSubmitted from "./components/Teacher Form/TeacherFormSubmitted";
import TeacherEducationForm from "./components/Teacher Form/TeacherEducationForm";
import Profile from "./components/General Setting/Profile";
import Stepform from "./components/Stepform/Stepform";
import Teacherform from "./components/Teacher Form/Teacherform";
import Studentform from "./components/Student form/Studentform";
import StudentRequirement from "./components/Student form/StudentRequirement";
import BasicDetail from "./BasicDetail";
import Tutorform from "./components/Teacher Form/Tutorform";
import Phone_verification from "./Phone_verification";
import NameSetting from "./components/General Setting/NameSetting";
import ChangeEmail from "./components/General Setting/ChangeEmail";
import ChangeName from "./components/General Setting/ChangeName";
import ChangeNumber from "./components/General Setting/ChangeNumber";
import ProfilePhoto from "./ProfilePhoto";
import ReferandEarn from "./components/General Setting/ReferandEarn";
// import RequestTutor from "./RequestTutor";
import StudentAccountSetting from "./components/Student form/StudentAccountSetting";
import SubjectTeach from "./components/Teacher Form/SubjectTeach";
import TeachingandProfessional from "./components/Teacher Form/TeachingandProfessional";
import UploadIDProof from "./UploadIDProof";
import AcountSetting from "./components/General Setting/GeneralSetting";
import Findtutor from "./components/tutor/Findtutor";
import Home from "./components/Home/Home";
import LoginVarification from "./components/Login/LoginVarification";
import TeacherPosts from "./TeacherPosts";
import EmailVarified from "./components/Login/Afterverify";
import RequestTutor from "./components/RequestTutor/RequestTutor";
import Verifytutor from "./components/RequestTutor/Verifytutor";
import AfterVerifytutor from "./components/RequestTutor/AfterVerifytutor";
import Tutorphoneverify from "./components/RequestTutor/Tutorphoneverify";
import Coin from "./components/coins/Coin";
// import RequestTutor from "./components/Teacher Form/RequestTutor";
import GetCoins from "./components/coins/GetCoins";
import ChosePaymentMethod from "./components/coins/ChosePaymentMethod";
import ResetPassward from "./components/Passward/ResetPassward";
import NewPassward from "./components/Passward/NewPassward";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RequestDetails from "./components/Teacher Form/RequestDetails";
import StudentClient from "./components/Student form/StudentClient";
import AllMessages from "./AllMessages";
import UnreadMessages from "./UnreadMessages";
import StudentAcountOpening from "./components/General Setting/StudentAcountOpening";
import ShowContactDetail from "./components/General Setting/ShowContactDetail";
import Invitefriends from "./components/coins/Invitefriends";
import Payment from "./components/coins/Payment";
import TutorProfile from "./components/Profile/TutorProfile";
import YourBasicInfo from "./components/Stepform/YourBasicInfo";
import Address from "./components/Stepform/Address";
import Education from "./components/Stepform/Education";
import Professional from "./components/Stepform/Professional";
import TeachingDetails from "./components/Stepform/TeachingDetails2";
import Profile_Description from "./components/Stepform/Profile Description";
import MyJobs from "./components/jobs/MyJobs";
import Reviews from "./components/jobs/Reviews";
import CourseTeach from "./components/Edit_profile/CourseTeach";
import GoPremium from "./components/Edit_profile/GoPremium";
import Message from "./components/Message";
import Abhi from "./components/Stepform/abhi";

function App() {

const accessToken = localStorage.getItem('accessToken');

  // Redirect to the login page if the user is not logged in
  const PrivateRoute = ({ element, ...props }) => {
    const accessToken = localStorage.getItem('accessToken');
  
    return accessToken ? (
      // User is logged in, render the provided element
      element
    ) : (
      // User is not logged in, redirect to the login page
      <Navigate to="/login" />
    );
  };
  
  return (
    <>
      <ToastContainer />
      <Header />
      <Routes>
        <Route path="/" element={<Home></Home>} />
        <Route path="/findtutor" element={<Findtutor></Findtutor>} />
        <Route path="/Login" element={<LoginPage />} />
        <Route path="/verify" element={<VerifyEmail />} />
        <Route path="/Tutorform" element={<Tutorform />} />
        <Route path="/Phone_verification" element={<Phone_verification />} />
        <Route path="/change-email" element={<ChangeEmail />} />
        <Route path="/changeNumber" element={<ChangeNumber />} />
        <Route path="/Name-setting" element={<ChangeName />} />
        <Route path="/ProfilePhoto" element={<ProfilePhoto />} />
        
        <Route
          path="/GS"
          element={<PrivateRoute element={<AcountSetting />} />}
        />

        <Route path="/TPP2" element={<SinglePage />} />
        <Route path="/tutor-profile" element={<TutorProfile />} />
        <Route path="/findtutor2" element={<TeacherPosts />} />
        <Route path="/VE" element={<EmailVarified />} />
        <Route path="/tpd" element={<TeacherProfileDashboard />} />


        



        <Route
          path="/SFP"
          element={<Stepform />}
        >
          <Route
            path="basic"
            element={<YourBasicInfo />}
          />
          <Route
            path="address"
            element={<Address />}
          />

          <Route
            path="subject"
            element={<SubjectTeach />}
          />

          <Route
            path="education"
            element={<Education />}
          />


          <Route
            path="professional"
            element={<Professional />}
          />


          <Route
            path="teach_detail"
            element={<TeachingDetails />}
          />

          <Route
            path="pro_des"
            element={<Profile_Description />}
          />

        </Route>


        <Route path="/request-tutor" element={<RequestTutor />} />
        <Route path="/Verifytutor" element={<Verifytutor />} />
        <Route path="/AfterVerifytutor" element={<AfterVerifytutor />} />
        <Route path="/Tutorphoneverify" element={<Tutorphoneverify />} />
        <Route path="/logout" element={<Home></Home>} />
        <Route path="/Coin" element={<Coin />} />
        {/* <Route path="/RequestTutor" element={<RequestTutor/>}/> */}
        <Route path="GetCoins" element={<GetCoins />} />
        <Route path="/ChosePaymentMethod" element={<ChosePaymentMethod />} />
        <Route path="/ResetPassward" element={<ResetPassward />} />
        <Route path="/NewPassward" element={<NewPassward />} />
        <Route path="/RequestDetails" element={<RequestDetails />} />
                <Route
                path="/StudentClient"
                element={<PrivateRoute element={<StudentClient />} />}
              />
      
        <Route path="/AllMessages" element={<AllMessages />} />
        <Route path="/UnreadMessages" element={<UnreadMessages />} />
        <Route path="/studentAcountOpening" element={<StudentAcountOpening />} />
        <Route path="/showContactDetail" element={<ShowContactDetail />} />
        <Route path="/ReferandEarn" element={<ReferandEarn />} />
        <Route path="/Invitefriends" element={<Invitefriends />} />
        <Route path="/Payment" element={<Payment />} />
        <Route path="/MyJobs" element={<MyJobs />} />
        <Route path="/Reviews" element={<Reviews />} />
        <Route path="/CourseTeach" element={<CourseTeach />} />
        <Route path="/GoPremium" element={<GoPremium />} />
        <Route path="/Message" element={<Message />} />
        <Route path="/abhi" element={<Abhi/>} />
      </Routes>

      {/* <Coin/>   */}
      {/* <GetCoins/>  */}
      {/* <ChosePaymentMethod/>              */}
      <Footer />
    </>
  );
}

export default App;
