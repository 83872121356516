import React, { useState } from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import axios from "../../settings/axiosConfig.jsx";
import { Link } from "react-router-dom";
import { getOptions } from "../../settings/options.jsx";
import { useNavigate } from 'react-router-dom';




const SubYouTeach = () => {
	// const [subjects, setSubjects] = useState([]);
	const navigate = useNavigate();

    const handleSubjectChange = (e) => {
		setTutorSubjectData({ ...tutorSubjectData, [e.target.name]: e.target.value });
	};

    const [tutorSubjectData, setTutorSubjectData] = useState({
		from_level_id: "",
		to_level_id: "",
		subject_id: ""
	});
    const [options, setOptions] = useState({
		gender: [],
		budget: [],
		fee_type: [],
		employment_status: [],
		level: [],
		degree_type: [],
		tutor_educational_association: [],
		tutor_experience_association: []
	});

	useEffect(() => {
		const optionNames = ["gender", "degree_type", "tutor_educational_association", "tutor_experience_association", "budget", "fee_type", "employment_status", "level"];

		getOptions(optionNames)
			.then(result => {
				setOptions(result);
			})
			.catch(error => {
				// Handle the error
			});
	}, []);

	console.log(options);


	// // Profile Information
	const [basicFormData, setBasicFormData] = useState({
		strength: "",
		gender: "",
		date_of_birth: ""
	});

	// const [basicErrorMessages, setBasicErrorMessages] = useState(false);
	// const [basicSuccessMessage, setBasicSuccessMessage] = useState(false);

	const handleBasicInfoChange = (e) => {
		setBasicFormData({ ...basicFormData, [e.target.name]: e.target.value });
	};

    const [chargesFormData, setChargesFormData] = useState({
		fee_type_id: "",
		fee_currency: "",
		min_charge_in_tutor_currency: "",
		max_charge_in_tutor_currency: "",
		fee_vary_detail: "",
		years_of_experience: "",
		have_digital_pen: "",
		help_with_hw_assignment: "",
		currently_full_time_status: "",
		looking_for_employment_status: "",
	});

	const [subjects, setSubjects] = useState([]);

	// GET SUBJECTS STARTS
	useEffect(() => {
		axios.get('/subjects')
			.then((response) => {
				if (response.data.success) {
					setSubjects(response.data.data);
				}
			})
			.catch((error) => {
			});
	}, []);
    const handleTutorProfile = async (e, formSection) => {
		e.preventDefault();
		try {

			switch (formSection) {
				case 'basicInfo':
					const metaResponse = await axios.post(`tutor-meta`, basicFormData);
					const profileResponse = await axios.put(`users/profile`, basicFormData);
					console.log(profileResponse);
					break;

				case 'subjects':
					const subjectResponse = await axios.post(`tutor-subject`, tutorSubjectData);
					console.log(subjectResponse)
					break;
				case 'charges':
					const chargesResponse = await axios.post(`tutor-charges`, chargesFormData);
					break;
				default:
					break;
			}
			navigate('/SFP/education');
		} catch (error) {
		}
	};
	const [locationFormData, setLocationFormData] = useState({
		country_id: "",
		city_name: "",
		state_name: "",
	});

	const handleLocationChange = (e) => {
		setLocationFormData({ ...locationFormData, [e.target.name]: e.target.value });
	};
	const handleChargesChange = (e) => {
		setChargesFormData({ ...chargesFormData, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		axios.get('/users/profile')
			.then((response) => {
				const tutorInfo = response.data.data;

				if (tutorInfo.tutor_information) {
					setBasicFormData({
						strength: tutorInfo.tutor_information.strength,
						gender: tutorInfo.gender,
						date_of_birth: tutorInfo.date_of_birth
					})

				}
				if (tutorInfo.tutor_location) {
					setLocationFormData(tutorInfo.tutor_location);
				}

				if (tutorInfo.charges) {
					setChargesFormData(tutorInfo.charges);
				}

				if (tutorInfo.tutor_subjects) {
					setTutorSubjectData(tutorInfo.tutor_subjects);
				}

				// setSubjectData(tutorInfo.tutor_subjects);
			})
			.catch((error) => {
				console.error('Error fetching user profile:', error);
			});
	}, []);

  return (
   <>
    <div>
	<div id="msform">
										{/* progressbar */}
										<ul id="progressbar">
											<li className="active" id="account">
												<strong>Your basic info</strong>
											</li>
											<li className="active" id="personal">
												<strong>Address </strong>
											</li>
											<li className="active" id="payment">
												<strong>Subjects you Teach</strong>
											</li>
											<li id="confirm">
												<strong>Education</strong>
											</li>
											<li id="confirm1">
												<strong>Professional</strong>
											</li>
											<li id="TeachingDetails">
												<strong>Teaching Details</strong>
											</li>
											<li id="ProfileDescription">
												<strong>Profile Description</strong>
											</li>
										</ul>
										

									</div>
   
    <fieldset className="Subjects_you_Teach mx-auto">
	<section className="form-card">
		{/* <h3>Subjects you Teach <a href="#">Add a subject</a></h3> */}

		<div className="mb-4">
			<label htmlFor="subject" className="form-label m-0">
				Subject (one at a time)
			</label>

			<select className="form-control" id="subject" name="subject_id" data-tags="true" onChange={handleSubjectChange}>
				<option>Select Subject</option>
				{subjects.map((subject, index) => (
<option key={index} value={subject.id}>
	{subject.title}
</option>
				))}
			</select>

			<a href=""> If not in options above, add a new subject.</a>
		</div>

		<div className="mb-4">
			<label htmlFor="from_level_id" className="form-label">From level</label>
			<select id="from_level_id" className="form-select" aria-label="Default select example" name="from_level_id" onChange={handleSubjectChange}>
				<option className="title">-- Select Level --</option>
				{
options.level.map((help, index) => (
	<optgroup key={index} label={help.option}>
		{
			help.sub_options.map((sublevel, ind) => (
				<option key={ind} value={sublevel.id}>{sublevel.option}</option>
			))
		}
	</optgroup>
))
				}
			</select>
		</div>

		<div className="mb-4">
			<label htmlFor="validationCustom02" className="form-label">To level</label>
			<select id="to_level_id" className="form-select" aria-label="Default select example" name="to_level_id" onChange={handleSubjectChange}>
				<option className="title">-- Select Level --</option>
				{
options.level.map((help, index) => (
	<optgroup key={index} label={help.option}>
		{
			help.sub_options.map((sublevel, ind) => (
				<option key={ind} value={sublevel.id}>{sublevel.option}</option>
			))
		}
	</optgroup>
))
				}
			</select>
		</div>

	</section>
	<Link to="/SFP/address">

	<input type="button" name="previous" className="previous action-button-previous btn btn-outline-secondary" defaultValue="Previous" />
	</Link>
	{/* <Link to="/SFP/education"> */}
	<input type="button"  className="btn btn-primary ms-4" defaultValue="Next Step" onClick={(e) => handleTutorProfile(e, 'subjects')} />
	{/* </Link> */}
</fieldset>
</div>
   </>
  )
}

export default SubYouTeach