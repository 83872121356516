import React from "react";
import { NavLink } from "react-router-dom";
const RequestDetails = () => {
  const cutter = () => {
    let cut = document.getElementById("cut-item");
    cut.style.display = "none";
  };
  const cutter2 = () => {
    let cut = document.getElementById("cut-item2");
    cut.style.display = "none";
  };

  return (
    <>
      <div className="container">
        <div className="Subjects_you_Teach mx-auto">
          <div id="cut-item" className="row border mt-5 LoginVarification-cls ma">
            <div className="col-10" style={{ padding: "0" }}>
              <div className="w-100 icon-full-div">
                <div className="bg-clr-green h-100 icon-div position-relative">
                  <div className="skew-div position-absolute h-100 position-absolute"></div>
                  <i className="fa-solid fa-check" style={{ color: "white", fontWeight: "900", fontSize: "33px", textAlign: "center", marginLeft: "8px", margin: "10px" }}></i>
                </div>
                <p className="verification-text text-start" style={{ paddingTop: "0", marginLeft: "20px", width: "76%" }}>
                  Your requirement has been posted. We will email you when a tutor responds to your post.
                </p>
              </div>
            </div>
            <div className="col-2">
              <i className="fa-solid fa-delete-left" onClick={cutter} style={{ marginTop: "13px", marginTop: "13px", float: "right" }}></i>
            </div>
          </div>
          <div id="cut-item2" className="row border mt-5 LoginVarification-cls ma">
            <div className="col-10" style={{ padding: "0" }}>
              <div className="w-100 icon-full-div">
                <div className="bg-clr-green h-100 icon-div position-relative">
                  <div className="skew-div position-absolute h-100 position-absolute"></div>
                  <img src="teacher-mk/warning.png" alt="not loading" className="icon-img img-fluid ma" />
                </div>
                <p className="verification-text text-start" style={{ paddingTop: "0", marginLeft: "20px", width: "71%" }}>
                  Verification skipped for +1 6135550189
                </p>
              </div>
            </div>
            <div className="col-2">
              <i className="fa-solid fa-delete-left" onClick={cutter2} style={{ marginTop: "13px", marginTop: "13px", float: "right" }}></i>
            </div>
          </div>

          <div className="col">
            <div className="d-fb mt-4">
              <h2 className="text-start w-75">My Requirements</h2>
              <NavLink to="/request-tutor" style={{ textDecoration: "none" }}>
                <button style={{ width: "171px", fontSize: "12px" }} type="button" className="btn after_email_btn m-0 btn bg-clr-green">
                  Post Your Study Needs
                </button>
              </NavLink>
            </div>
            <p className="mt-3 text-start txt-clr">Online English tutor required in Surrey</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga incidunt illo molestiae et sed dolore expedita, voluptate odit corrupti neque, dignissimos, culpa exercitationem. Inventore quo quae delectus fugiat ullam. Voluptatum, cumque sit! Consequatur nesciunt facere enim, cupiditate maiores aperiam facilis maxime dignissimos libero commodi...</p>
          </div>
          <div className="text-start mt-4">
            <div className="d-fb-600  My_Requirements_details gap-3">
              <div className="d-flex align-items-baseline gap-2">
                <i className="fa-solid fa-car "></i>
                <p>1km</p>
              </div>
              <div className="d-flex align-items-baseline gap-2">
                <i className="fa-solid fa-dollar-sign"></i>
                <p className="">CA$1 (Fixed) (.73 USD)</p>
              </div>
              <div className="d-flex align-items-baseline gap-2">
                <i className="fa-solid fa-location-dot"></i>
                <p className="">Surrey, BC, Canada</p>
              </div>
            </div>
          </div>

          <div className="text-start mt-5 ab">
            <div className="d-flex">
              <NavLink to="/AllMessages" style={{ textDecoration: "none" }}>
                <button type="button" className="btn btn-view-messahges bg-clr-green after_email_btn">
                  View Messages
                </button>
              </NavLink>
              <i className="fa-solid fa-pen-to-square ms-3 txt-clr" style={{ marginTop: "12px" }}></i>
              <p className="ms-2 ">Edit</p>

              <i className="fa-solid fa-circle-xmark ms-3 txt-clr" style={{ marginTop: "12px" }}></i>
              <p className="ms-2 ">Close</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestDetails;
