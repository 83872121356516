import React from 'react'


const Footer = () => {
  return (
    <>
      <div className='w-100 ma bg-clr p-3 fs mt-5 foot'>
        <img src="teacher-mk/logo.png" alt="Logo image" className='img-fluid logo-cls logo-icon logo-i' />

        <p className="text-white mp">
          Contact 350 Avenue, New York, NY 10001

        </p>
        <p className="text-white">
          Email info@example.com, phone number 800-123-4567
        </p>
        <p className="text-white">
          Refer and Earn Coins | How it works - Students | Learning mind | FAQs | Pay teachers | Premium membership
        </p>
        <img src="teacher-mk/fb.png" className="fb" />
        <img src="teacher-mk/insta.png" className="fb insta" />
        <img src="teacher-mk/tw.png" className="fb twit" />
      </div>
    </>
  )
}


export default Footer
