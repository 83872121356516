import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

const Header = () => {
  const accessToken = localStorage.getItem('accessToken');
  const email = localStorage.getItem('email');
  const fname = localStorage.getItem('fname');
  const lname = localStorage.getItem('lname');
 
  const userType = window.localStorage.getItem('userType');

  const navigate = useNavigate();

  const handleLogout = () => {
    // Perform any additional cleanup or logout-related actions here
    // For example, clearing other data from local storage

    // Clear user-related data
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userType');
    localStorage.removeItem('email');
    localStorage.removeItem('fname');
    localStorage.removeItem('lname');
    // Redirect to the login page or any other desired page after logout
    navigate('/login');
  };
  return (
    <>
{accessToken ? (
                     <nav className="navbar  navbar-expand-lg">
                     {" "}
                     <div className="container-fluid">
                       <img src="teacher-mk/logo.png" alt="Tutor Logo image" className="img-fluid logo-cls" />
                       <div className="d-flex">
                         <button className="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                           <span className="navbar-toggler-icon"></span>
                         </button>
                         <div className="offcanvas offcanvas-start justify-content-center w-auto" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                           <div className="offcanvas-body  justify-content-end">
                             <div>
                               <button type="button" className="btn-close toggle_sidebar_close_btn ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>{" "}
                               <div className=" d-flex align-items-center gap-2 justify-content-end">
                                 <NavLink to="/Message">
                                   <a href="#">
                                     <i class="fa-solid fa-envelope fa-2xl me-2"></i>
                                     <span>Message</span>
                                   </a>
                                 </NavLink>
                                 <div className="dropdown a">
                                   <a className="btn dropdown_header_btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                     {/* <i className="fa-solid fa-user fa-2xl"></i> */}
             
                                     <div className="Header_user_profile d-flex align-items-center gap-2 ">
                                       <img src="teacher-mk/user_3177440.png" alt="Header_user_profile" />
                                       <p className="p-0 user_header_name">{fname} {lname}</p>
                                       {/* <img src="teacher-mk/christina-wocintechchat-com-0Zx1bDv5BNY-unsplash.jpg" alt="Header_user_profile" /> */}
                                     </div>
                                   </a>
                                   <ul className="dropdown-menu dwopdown_header">
                                     <li>
                                       <a className="dropdown-item">Accounts</a>
                                     </li>
                                     {userType === 'student' && (
                                     <NavLink to="/StudentClient">
                                       {" "}
                                       <li>
                                         <a className="dropdown-item" href="#">
                                           <i className="fa-solid fa-user"></i>
                                           Student/Client
                                         </a>
                                       </li>
                                     </NavLink>
                                     )}
                                     {userType === 'tutor' && (
                                     <NavLink to="/StudentClient">
                                       {" "}
                                       <li>
                                         <a className="dropdown-item" href="#">
                                           <i className="fa-solid fa-chalkboard-user"></i>
                                           Teacher/Expert
                                         </a>
                                       </li>
                                     </NavLink>
                                     )}
                                     <NavLink to="/logout">
                                       {" "}
                                       <li>
                                         <a className="dropdown-item" href="#">
                                           <i className="fa-solid fa-cart-shopping"></i>
                                           store{" "}
                                         </a>
                                       </li>
                                     </NavLink>
                                     <hr className="m-0" />
                                     <li>
                       {" "}
                       <a className="dropdown-item pb-0">{fname} {lname}</a>
                     </li>
                     <li>
                       {" "}
                       <a className="dropdown-item pt-0 user_mail">{email}</a>
                     </li>
                                     <hr className="m-0" />
                                     {userType === 'tutor' && (
                                     <NavLink to="/SP">
                                       {" "}
                                       <li>
                                         <a className="dropdown-item" href="#">
                                           <i className="fa-solid fa-user"></i>
                                           My Teacher Profile{" "}
                                         </a>
                                       </li>
                                     </NavLink>
                                     )}
                                     <NavLink to="/GS">
                                       {" "}
                                       <li>
                                         <a className="dropdown-item" href="#">
                                           <i className="fa-solid fa-gear"></i>
                                           Settings{" "}
                                         </a>
                                       </li>
                                     </NavLink>
                                                  {accessToken ? (
                             <li>
                             <a className="dropdown-item" href="#" onClick={handleLogout}>
                               <i className="fa-solid fa-right-from-bracket"></i>
                               Sign out{" "}
                             </a>
                           </li>
                     ) : (
                       <li style={{ display: "none" }}>
                               <a className="dropdown-item" href="#">
                                 <i className="fa-solid fa-right-from-bracket"></i>
                                 Sign out{" "}
                               </a>
                             </li>
                
                     )}
                                   </ul>
                                 </div>{" "}
                               </div>
                               <ul className="navbar-nav  mb-2 mb-lg-0  align-items-lg-center align-items-md-start gap-3">
                                 <NavLink to="/" style={{ textDecoration: "none" }}>
                                   <li className="nav-item">
                                     <a className="nav-link active fs-6 me-0" style={{ textDecoration: "none" }} aria-current="page" href="#">
                                       Dashboard
                                     </a>
                                   </li>
                                 </NavLink>
                                 <div className="dropdown">
                                   <button className="btn  dropdown-toggle px-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                     Jobs
                                   </button>
                                   <ul className="dropdown-menu">
                                     <NavLink to="/MyJobs" style={{ textDecoration: "none" }}>
                                       <li className="nav-item">
                                         <a className="nav-link fs-6" href="#">
                                           My jobs
                                         </a>
                                       </li>
                                     </NavLink>
                                     <NavLink to="/MyJobs" style={{ textDecoration: "none" }}>
                                       <li className="nav-item">
                                         <a className="nav-link fs-6" href="#">
                                           Search all jobs
                                         </a>
                                       </li>
                                     </NavLink>
             
                                     <NavLink to="/MyJobs" style={{ textDecoration: "none" }}>
                                       <li className="nav-item">
                                         <a className="nav-link fs-6" href="#">
                                           Search online jobs
                                         </a>
                                       </li>
                                     </NavLink>
                                     <NavLink to="/Reviews" style={{ textDecoration: "none" }}>
                                       <li className="nav-item">
                                         <a className="nav-link fs-6" href="#">
                                           Reviews
                                         </a>
                                       </li>
                                     </NavLink>
                                   </ul>
                                 </div>
             
                                 {/* second dropdown 2 */}
                                 <div className="dropdown">
                                   <button className="btn dropdown-toggle px-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                     Wallet
                                   </button>
                                   <ul className="dropdown-menu">
                                     <NavLink to="/coin" style={{ textDecoration: "none" }}>
                                       <li className="nav-item">
                                         <a className="nav-link fs-6" href="#">
                                           Coin Wallet
                                         </a>
                                       </li>
                                     </NavLink>
                                     <NavLink to="/GetCoins" style={{ textDecoration: "none" }}>
                                       <li className="nav-item">
                                         <a className="nav-link fs-6" href="#">
                                           Buy Coins
                                         </a>
                                       </li>
                                     </NavLink>
                                     <NavLink to="/Payment" style={{ textDecoration: "none" }}>
                                       <li className="nav-item">
                                         <a className="nav-link fs-6" href="#">
                                           Payments
                                         </a>
                                       </li>
                                     </NavLink>
                                     <NavLink to="/Invitefriends" style={{ textDecoration: "none" }}>
                                       <li className="nav-item">
                                         <a className="nav-link me-0 fs-6" href="#">
                                           Invite friends for coins
                                         </a>
                                       </li>
                                     </NavLink>
                                     <NavLink to="/ReferandEarn" style={{ textDecoration: "none" }}>
                                       <li className="nav-item">
                                         <a className="nav-link fs-6" href="#">
                                           Refer to get coins
                                         </a>
                                       </li>
                                     </NavLink>
                                   </ul>
                                 </div>
             
                                 {/* 2nd dropdown close */}
             
                                 {/* second dropdown 3=====================*/}
             
                                 <div className="dropdown">
                                   <button className="btn dropdown-toggle px-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                     Edit Profile
                                   </button>
                                   <ul className="dropdown-menu">
                                     <NavLink to="/SFP" style={{ textDecoration: "none" }}>
                                       <li className="nav-item">
                                         <a className="nav-link fs-6" href="#">
                                           Personal Details
                                         </a>
                                       </li>
                                     </NavLink>
                                     <NavLink to="" style={{ textDecoration: "none" }}>
                                       <li className="nav-item">
                                         <a className="nav-link fs-6" href="#" data-bs-toggle="modal" data-bs-target="#exampProfilePhoto">
                                           Photo
                                         </a>
                                       </li>
                                     </NavLink>
             
                                     <NavLink to="/Tutorphoneverify" style={{ textDecoration: "none" }}>
                                       <li className="nav-item">
                                         <a className="nav-link fs-6" href="#">
                                           Phone
                                         </a>
                                       </li>
                                     </NavLink>
                                     <NavLink to="/CourseTeach" style={{ textDecoration: "none" }}>
                                       <li className="nav-item">
                                         <a className="nav-link fs-6" href="#">
                                           Courses
                                         </a>
                                       </li>
                                     </NavLink>
                                     <NavLink to="" style={{ textDecoration: "none" }}>
                                       <li className="nav-item">
                                         <a className="nav-link fs-6" href="#">
                                           View Profile
                                         </a>
                                       </li>
                                     </NavLink>
                                   </ul>
                                 </div>
             
                                 <div className="header_nav_links px-4">
                                   <NavLink to="/GoPremium" style={{ textDecoration: "none" }}>
                                     <a href="#" className="login-cls">
                                       {" "}
                                       Go Premium{" "}
                                     </a>
                                   </NavLink>{" "}
                                 </div>
                               </ul>
                             </div>
                           </div>{" "}
                         </div>
                       </div>
                     </div>{" "}
                   </nav>
        ) : (
          
      <nav className="navbar  navbar-expand-lg">
      {" "}
      <div className="container-fluid">
        <img src="teacher-mk/logo.png" alt="Tutor Logo image" className="img-fluid logo-cls" />
        <div className="d-flex">
          <button className="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="offcanvas offcanvas-start justify-content-center w-auto" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-body  justify-content-end">
              <button type="button" className="btn-close toggle_sidebar_close_btn ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>{" "}
              <ul className="navbar-nav  mb-2 mb-lg-0 me-28 align-items-lg-center align-items-md-start gap-3">
                <NavLink to="/" style={{ textDecoration: "none" }}>
                  <li className="nav-item">
                    <a className="nav-link active fs-6" style={{ textDecoration: "none" }} aria-current="page" href="#">
                      Home
                    </a>
                  </li>
                </NavLink>
                <div className="dropdown">
                  <button className="btn  dropdown-toggle px-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Find tutors
                  </button>
                  <ul className="dropdown-menu">
                    <NavLink to="/request-tutor" style={{ textDecoration: "none" }}>
                      <li className="nav-item">
                        <a className="nav-link fs-6" href="#">
                          Request a tutor
                        </a>
                      </li>
                    </NavLink>
                    <NavLink to="/findtutor" style={{ textDecoration: "none" }}>
                      <li className="nav-item">
                        <a className="nav-link fs-6" href="#">
                          All Tutors
                        </a>
                      </li>
                    </NavLink>

                    <NavLink to="/findtutor" style={{ textDecoration: "none" }}>
                      <li className="nav-item">
                        <a className="nav-link fs-6" href="#">
                          Online Tutors
                        </a>
                      </li>
                    </NavLink>
                    <NavLink to="/findtutor" style={{ textDecoration: "none" }}>
                      <li className="nav-item">
                        <a className="nav-link fs-6" href="#">
                          Home Tutors
                        </a>
                      </li>
                    </NavLink>
                  </ul>
                </div>

                {/* second dropdown 2 */}
                <div className="dropdown">
                  <button className="btn dropdown-toggle px-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Find tutors Jobs
                  </button>
                  <ul className="dropdown-menu">
                    <NavLink to="/findtutor2" style={{ textDecoration: "none" }}>
                      <li className="nav-item">
                        <a className="nav-link fs-6" href="#">
                          All Tutors Jobs
                        </a>
                      </li>
                    </NavLink>
                    <NavLink to="/findtutor2" style={{ textDecoration: "none" }}>
                      <li className="nav-item">
                        <a className="nav-link fs-6" href="#">
                          Online Tutors Jobs
                        </a>
                      </li>
                    </NavLink>
                    <NavLink to="/findtutor2" style={{ textDecoration: "none" }}>
                      <li className="nav-item">
                        <a className="nav-link fs-6" href="#">
                          Home Tutors Jobs
                        </a>
                      </li>
                    </NavLink>
                  </ul>
                </div>
                <NavLink to="/request-tutor" style={{ textDecoration: "none" }}>
                  <li className="nav-item">
                    <a className="nav-link active fs-6" style={{ textDecoration: "none" }} aria-current="page" href="#">
                      Assignment help
                    </a>
                  </li>
                </NavLink>
              </ul>
              
             
              {accessToken ? (
                 <div className="header_nav_links px-4" style={{ display: "none" }}>
          <a href="#" className="login-cls"  >
            Logout
          </a>
          </div>
        ) : (
          <div className="header_nav_links px-4">
          <NavLink to="/login" style={{ textDecoration: "none" }}>
            <a href="#" className="login-cls">
              Login
            </a>
          </NavLink>
           </div>
        )}
             
              <div className="header_nav_links px-4">
                <NavLink to="/request-tutor" style={{ textDecoration: "none" }}>
                  <a href="#" className="login-cls">
                    {" "}
                    Request a tutor{" "}
                  </a>
                </NavLink>{" "}
              </div>
            </div>{" "}
          </div>


         



          {accessToken ? (
                   <div className="dropdown a me-5">
                   <a className="btn dropdown_header_btn" href="#" style={{ width: "47px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                     {/* <i className="fa-solid fa-user fa-2xl"></i> */}
                     <div className="Header_user_profile">
                       <img src="teacher-mk/user_3177440.png" alt="Header_user_profile" />
                       {/* <img src="teacher-mk/christina-wocintechchat-com-0Zx1bDv5BNY-unsplash.jpg" alt="Header_user_profile" /> */}
                     </div>
                   </a>
                   <ul className="dropdown-menu dwopdown_header">
                     <li>
                       <a className="dropdown-item">Accounts</a>
                     </li>
                     <NavLink to="/StudentClient">
                       {" "}
                       <li>
                         <a className="dropdown-item" href="#">
                           <i className="fa-solid fa-user"></i>
                           Student/Client
                         </a>
                       </li>
                     </NavLink>
                     <NavLink to="/StudentClient">
                       {" "}
                       <li>
                         <a className="dropdown-item" href="#">
                           <i className="fa-solid fa-chalkboard-user"></i>
                           Teacher/Expert
                         </a>
                       </li>
                     </NavLink>
                     <NavLink to="/logout">
                       {" "}
                       <li>
                         <a className="dropdown-item" href="#">
                           <i className="fa-solid fa-cart-shopping"></i>
                           store{" "}
                         </a>
                       </li>
                     </NavLink>
                     <hr className="m-0" />
                     <li>
                       {" "}
                       <a className="dropdown-item pb-0">{fname} {lname}</a>
                     </li>
                     <li>
                       {" "}
                       <a className="dropdown-item pt-0 user_mail">{email}</a>
                     </li>
                     <hr className="m-0" />
                     <NavLink to="/SP">
                       {" "}
                       <li>
                         <a className="dropdown-item" href="#">
                           <i className="fa-solid fa-user"></i>
                           My Teacher Profile{" "}
                         </a>
                       </li>
                     </NavLink>
                     <NavLink to="/GS">
                       {" "}
                       <li>
                         <a className="dropdown-item" href="#">
                           <i className="fa-solid fa-gear"></i>
                           Settings{" "}
                         </a>
                       </li>
                     </NavLink>
                     
                     {accessToken ? (
                       <li>
                       <a className="dropdown-item" href="#" onClick={handleLogout}>
                         <i className="fa-solid fa-right-from-bracket"></i>
                         Sign out{" "}
                       </a>
                     </li>
               ) : (
                 <li style={{ display: "none" }}>
                         <a className="dropdown-item" href="#">
                           <i className="fa-solid fa-right-from-bracket"></i>
                           Sign out{" "}
                         </a>
                       </li>
          
               )}
                       
                     
                   </ul>
                 </div>
        ) : (
          <div className="dropdown a me-5"  style={{ display: "none" }}>
          <a className="btn dropdown_header_btn" href="#" style={{ width: "47px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {/* <i className="fa-solid fa-user fa-2xl"></i> */}
            <div className="Header_user_profile">
              <img src="teacher-mk/user_3177440.png" alt="Header_user_profile" />
              {/* <img src="teacher-mk/christina-wocintechchat-com-0Zx1bDv5BNY-unsplash.jpg" alt="Header_user_profile" /> */}
            </div>
          </a>
          <ul className="dropdown-menu dwopdown_header">
            <li>
              <a className="dropdown-item">Accounts</a>
            </li>
            <NavLink to="/StudentClient">
              {" "}
              <li>
                <a className="dropdown-item" href="#">
                  <i className="fa-solid fa-user"></i>
                  Student/Client
                </a>
              </li>
            </NavLink>
            <NavLink to="/StudentClient">
              {" "}
              <li>
                <a className="dropdown-item" href="#">
                  <i className="fa-solid fa-chalkboard-user"></i>
                  Teacher/Expert
                </a>
              </li>
            </NavLink>
            <NavLink to="/logout">
              {" "}
              <li>
                <a className="dropdown-item" href="#">
                  <i className="fa-solid fa-cart-shopping"></i>
                  store{" "}
                </a>
              </li>
            </NavLink>
            <hr className="m-0" />
            <li>
              {" "}
              <a className="dropdown-item pb-0">{fname} {lname}</a>
            </li>
            <li>
              {" "}
              <a className="dropdown-item pt-0 user_mail">{email}</a>
            </li>
            <hr className="m-0" />
            <NavLink to="/SP">
              {" "}
              <li>
                <a className="dropdown-item" href="#">
                  <i className="fa-solid fa-user"></i>
                  My Teacher Profile{" "}
                </a>
              </li>
            </NavLink>
            <NavLink to="/GS">
              {" "}
              <li>
                <a className="dropdown-item" href="#">
                  <i className="fa-solid fa-gear"></i>
                  Settings{" "}
                </a>
              </li>
            </NavLink>
            
            {accessToken ? (
              <li>
              <a className="dropdown-item" href="#" onClick={handleLogout}>
                <i className="fa-solid fa-right-from-bracket"></i>
                Sign out{" "}
              </a>
            </li>
      ) : (
        <li style={{ display: "none" }}>
                <a className="dropdown-item" href="#">
                  <i className="fa-solid fa-right-from-bracket"></i>
                  Sign out{" "}
                </a>
              </li>
 
      )}
              
            
          </ul>
        </div>
        )}







       
        </div>
      </div>{" "}
    </nav>
   
        )}
                
    

      {/* =================second profile header start============== */}

 
      {/* <!--5 Name settings popup  --> */}

      <div className="modal fade" id="exampProfilePhoto" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">
              <div className="container">
                <div className="Profile_Photo_page">
                  <h3>Profile Photo</h3>

                  <div className="input-group my-5">
                    <input type="file" className="form-control Old_email" id="exampleInputEmail1" aria-describedby="emailHelp" readonly />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>    
     
      </>
  );
};

export default Header;