import React from "react";
import { NavLink } from "react-router-dom";

const MyJobs = () => {
  return (
    <>
      <div className="cointainer mt-5 Small_containers">
        <div className="row">
          <div className="Subjects_you_Teach mx-auto My_jobs_page">
            <h3>My Jobs</h3>
            <p>
              you have not applied to any Job. &nbsp;&nbsp;
              <NavLink to="/findtutor2">
                <a href="#">Search jobs and apply </a>
              </NavLink>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyJobs;
