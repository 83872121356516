import React from 'react'

const SubjectTeach = () => {
	return (
		<>
			<section className="py-2">
				<div className="container py-4">
					<div className="d-flex justify-content-center my-5">
						<div className="Subjects_you_Teach">
							<h3>Subjects you Teach <a href="#">Add a subject</a></h3>
							<form className="mt-4">

								<div className="mb-3">
									<label htmlFor="exampleFormControlInput1" className="form-label m-0">
										Subject (one at a time)
									</label>

									<select className="form-control" id="exampleFormControlInput1" multiple="multiple" data-tags="true">
										<option>English</option>
										<option>Hindi</option>
										<option>Punjabi</option>
									</select>

									<a href=""> If not in options above, add a new subject.</a>
								</div>
								<label htmlFor="validationCustom02" className="form-label mt-2">From level</label>
								<select className="form-select" aria-label="Default select example">
									<option className="title">-- Select Lowest Level --</option>
									<option value="1" className="title">-- Skill Level --</option>
									<option value="2">Beginner</option>
									<option value="3">Intermediate</option>
									<option value="4">Expert</option>
									<option value="4" className="title">-- Grades --</option>
									<option value="4">Preschool, Kindergarten, KG, Nursery</option>
									<option value="4">Grade 1</option>
									<option value="4">Grade 2</option>
									<option value="4">Grade 3</option>
									<option value="4">Grade 4</option>
									<option value="4">Grade 5</option>
									<option value="4">Grade 6</option>
									<option value="4">Grade 7</option>
									<option value="4">Grade 8</option>
									<option value="4">Grade 9</option>
									<option value="4">Grade 10</option>
									<option value="4">IGCSE</option>
									<option value="">GCSE</option>
									<option value="">O level</option>
									<option value="">Grade 11</option>
									<option value="">AS level</option>
									<option value="">A2 level</option>
									<option value="">A level</option>
									<option value="">Grade 12</option>
									<option value="">Diploma</option>
									<option value="">Bachelors/Undergraduate</option>
									<option value="">Masters/Postgraduate</option>
									<option value="">MPhil</option>
									<option value="">Doctorate/PhD</option>
									<option value="" className="title">-- Others --</option>
									<option value="">Adult/Casual learning</option>
								</select>

								<label htmlFor="validationCustom02" className="form-label mt-4">To level</label>
								<select className="form-select mb-5" aria-label="Default select example">
									<option selected className="title">-- Select Highest Level --</option>
									<option value="1">Beginner</option>
									<option value="2">Intermediate</option>
									<option value="3">Expert</option>
								</select>
								<a className="save" href="#">Save</a>
							</form>
						</div>
					</div>
				</div>
			</section>

		</>
	)
}

export default SubjectTeach