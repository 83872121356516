import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink } from "react-router-dom";

const EmailVarified = () => {
  const [isDivVisible, setIsDivVisible] = useState(true);

  // Function to toggle the visibility
  const toggleVisibility = () => {
    setIsDivVisible(!isDivVisible);
  };

  return (
    <>
      <div className="container EmailVarified-cls Subjects_you_Teach">
        {isDivVisible && (
          <div id="demo" className="row border mt-5  ma EmailVarified-cls">
            <div className="col-10" style={{ padding: "0" }}>
              <div className="w-100 icon-full-div">
                <div className="bg-clr-green h-100 icon-div position-relative">
                  <div className="skew-div position-absolute h-100 position-absolute"></div>
                  <i className="fa-solid fa-check " style={{ color: "white", fontSize: "36px", margin: "5px" }}></i>
                </div>
                <p className="verification-text" style={{ paddingTop: "0", marginLeft: "20px" }}>
                  Email varified
                </p>
              </div>
            </div>
            <div className="col-2">
              <a href="#" onClick={toggleVisibility}>
                <i className="fa-solid fa-delete-left fa-2xl" style={{ marginTop: "13px", marginTop: "22px", float: "right" }}></i>
              </a>
            </div>
          </div>
        )}

        <div className="mt-5 EmailVarified-cls ">
          <label htmlFor="validationCustom02" className="form-label mt-4">
            I am a
          </label>
          <select className="form-select" aria-label="Default select example">
            <option selected>Please select </option>
            <option value="1">Individual Teacher</option>
            <option value="2">Tutoring Company</option>
          </select>
          <div>
            <label htmlFor="validationCustom02" className="form-label mt-4">
              Full name
            </label>
            <input className="form-control" type="text" />
            <label htmlFor="validationCustom02" className="form-label mt-4">
              Company name
            </label>
            <input className="form-control" type="text" />
            <label htmlFor="validationCustom02" className="form-label mt-4">
              Role/Job title
            </label>
            <input className="form-control" type="text" />
            <label htmlFor="validationCustom02" className="form-label mt-4">
              Display name
            </label>
            <input className="form-control" type="text" />
          </div>
          <div className="text-start">
            <NavLink to="/SFP/basic">
              <a href="#" className="btn border mt-5 text-white after_email_btn">
                Submit
              </a>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVarified;
