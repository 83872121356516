import React from "react";
import { NavLink } from "react-router-dom";

const GetCoins = () => {
  const removeShowClass = () => { };

  return (
    <>
      <div className="cointainer mt-5">
        <div className="row">
          <div className="col-8 ma border GetCoins-cls mb-5">
            <h3 className="mt-5">How many coins do you want</h3>

            <select className="form-select form-select-sm w-75 ma" aria-label="Small select example">
              <option selected>Select</option>
              <option value="1">50 coins</option>
              <option value="2">100 coins</option>
              <option value="3">150 coins</option>
            </select>

            <div className="d-flex ma mt-4 w-50">
              <div className="dollar-div border">1 USD</div>
              <div className="dollar-div border ms-5  ">83.34 INR</div>
            </div>

            <button type="button" className="btn bg-clr-green mt-5 border   mb-4" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ width: "75%" }}>
              Get 50 coins
            </button>

            <div className="row mb-5">
              <div className="col ">
                <i className="fa-brands fa-paypal txt-clr ms-3" style={{ fontSize: "30px" }}></i>
                <i className="fa-brands fa-cc-stripe txt-clr ms-3" style={{ fontSize: "30px" }}></i>
                <i className="fa-brands fa-cc-visa txt-clr ms-3" style={{ fontSize: "30px" }}></i>
                <i className="fa-brands fa-cc-mastercard txt-clr ms-3" style={{ fontSize: "30px" }}></i>
                <i className="fa-brands fa-cc-amex txt-clr ms-3" style={{ fontSize: "30px" }}></i>
                <i className="fa-brands fa-cc-amazon-pay ms-3 txt-clr " style={{ fontSize: "30px" }}></i>
                <i className="fa-brands fa-cc-discover ms-3 txt-clr" style={{ fontSize: "30px" }}></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Button trigger modal --> */}

      {/* <!-- Modal --> */}

      <div className="modal fade " id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Billing Address
              </h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Full Name
                  </label>
                  <input type="name" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Country
                  </label>
                  <input type="name" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    State
                  </label>
                  <input type="name" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    City
                  </label>
                  <input type="name" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Postel
                  </label>
                  <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Address
                  </label>
                  <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Contact No
                  </label>
                  <input type="tel" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>

                {/* <button type="submit" className="btn btn-primary">Submit</button> */}
              </form>
            </div>

            <div className="modal-footer mb-1">
              <NavLink to="/ChosePaymentMethod">
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                  Submit
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetCoins;
